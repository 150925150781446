import React, {
  useContext, useEffect,
} from 'react'
import { PremiseContext } from '../../../PremiseTracker/PremiseContext'
import { NextRouter } from 'next/router'
import Link from 'next/link'
import PersonalDetailsForm, { PersonalDetailsFormData } from './PersonalDetailsForm/PersonalDetailsForm'
import {
  Grid, Typography, Collapse, Hidden,
  Box,
  useMediaQuery,

} from '@material-ui/core'
import classes from './CheckoutStep.styles'
import { Discount } from '../../../../utils/commonInterfaces'
import { ArrowBack } from '@material-ui/icons'
import getStepUrl from '../../getStepUrl'
import { useOrderContext } from '../../OrderContext'
import InstallationSection from './InstallationSection/InstallationSection'
import PaymentDetails from './PaymentDetails/PaymentDetails'
import TimeoutModal from './TimeoutModal/TimeoutModal'
import marketingEvents from '../../../../utils/marketing/marketingEvents'
import Basket from './Basket/Basket'
import OrderButton from './Basket/OrderButton'
import OrderError from './OrderError/OrderError'
import OrderProcessing from './OrderProcessing/OrderProcessing'
import SummarySection from './SummarySection/SummarySection'
import { ContainerWithPadding } from '../../../../styles/ContainerWithPadding'
import createCRMApiClient from '../../../../api/CRMApi'
import MediaImage from '../../../Media/MediaImage'
import Stepper from './Stepper/Stepper'
import {
  scrollStepIntoView, jumpToElement,
} from '../../../../utils'
import { ButterCMSComponent } from '../../../../butter'
import { SwitchingSection } from './SwitchingSection/SwitchingSection'
import { cx } from 'linaria'
import { getPackageLength } from '../../../../utils/getProductDetails'
import theme from '../../../../styles/theme'

interface Props {
  router: NextRouter;
  step: StepType;
  generalSettings: any;
  data: {
    components: ButterCMSComponent[];
  };
}

export interface AuditData {
  name: string;
  value: string;
}

export interface OrderDetails {
  data: PersonalDetailsFormData;
  discount: Discount | null;
  promo: any | null;
}

export type ChangeCollapsible = (nextCollapsible: StepType, previousCollapsible?: StepType) => void;

export type StepType =
  | 'broadband'
  | 'addons'
  | 'checkout'
  | 'checkout-details'
  | 'checkout-installation'
  | 'checkout-one-touch-switching'
  | 'checkout-payment'
  | 'checkout-order-summary'
  | 'checkout-order-processing'
  | 'checkout-order-confirmation'

export const checkOutPageSteps = [
  'checkout',
  'checkout-details',
  'checkout-installation',
  'checkout-one-touch-switching',
  'checkout-payment',
  'checkout-order-summary',
]

export default function CheckoutStep({
  router, step: currentStep, generalSettings, data,
}: Props) {
  const { premise } = useContext(PremiseContext)
  const {
    options, setOptions,
  } = useOrderContext()

  const {
    broadband,
  } = options

  const specialContractLengthList = generalSettings.components.find((e: any) => e.type === 'general')?.special_contract_length
  const specialContractLengthArray = specialContractLengthList ? specialContractLengthList.split('|') : []
  const productContractLength = String(getPackageLength(broadband))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    marketingEvents.onCheckoutOption(1)
    scrollStepIntoView('#stepper', 100)

    if (currentStep === 'checkout' || currentStep === 'checkout-details') {
      const headerRef: HTMLElement | null = document.querySelector('#header')
      if (headerRef) {
        jumpToElement(headerRef, false)
      }
    }

    return undefined
  }, [currentStep])

  useEffect(() => {
    async function handleCheckoutSummary() {
      if (currentStep !== 'checkout-order-summary') {
        return
      }

      marketingEvents.simpleEvent('order_summary', options)

      const correlationID = sessionStorage.getItem('OTSCorrelationID')
      if (!correlationID) {
        console.warn('No correlationID found in session storage')
        return
      }

      const client = createCRMApiClient()
      const otsRegistration = client.otsRegistrationResponse

      await fetchOtsRegistration(otsRegistration, correlationID)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function fetchOtsRegistration(otsRegistration: any, correlationID: string, attempts = 0): Promise<void> {
      const maxAttempts = 5
      try {
        const response = await otsRegistration.getOtsRegistrationResponse({ correlationID })

        if (response && response.data) {
          if (response.data.length > 1) {
            return
          }

          if (response.data.length === 1 && attempts < maxAttempts) {
            console.warn(`Response only contains one object. Retrying... (${attempts + 1}/${maxAttempts})`)
            await new Promise(resolve => setTimeout(resolve, 10000)) // 10 second delay before retrying
            return fetchOtsRegistration(otsRegistration, correlationID, attempts + 1)
          }

          console.warn('Max retry attempts reached.')
        }
      } catch (err) {
        console.error('Error fetching OTS Registration Response:', err)
      }
    }

    if (currentStep === 'checkout-details') {
      marketingEvents.ecommerceEvent('begin_checkout', options)
      setOptions({
        ...options,
        isDetailsStep: true,
      })
    }

    if (currentStep === 'checkout-payment') {
      marketingEvents.ecommerceEvent('add_payment_info', options)
    }

    if (currentStep === 'checkout-installation') {
      setOptions({
        ...options,
        reachedInstallationStep: true,
        isDetailsStep: false,
      })
    }

    async function handleOrderProcessing() {
      if (currentStep === 'checkout-order-processing') {
        marketingEvents.simpleEvent('order_processing', options)

        const newOrderFlowDataString = localStorage.getItem('new-order-flow')
        if (!newOrderFlowDataString) {
          console.error('Failed to retrieve new-order-flow data from local storage')
          return
        }

        const newOrderFlowData = JSON.parse(newOrderFlowDataString)
        const cartInstanceIdentifier = newOrderFlowData.cartInstanceIdentifier || 'defaultCartInstanceIdentifier'
        const channelID = newOrderFlowData.channel || 'WEB'

        const corelationID = sessionStorage.getItem('OTSCorrelationID')
        if (!corelationID) {
          console.error('Failed to retrieve OTSCorrelationID from session storage')
          return
        }

        // Retrieve other values if needed from local storage
        const loosingProviderName = localStorage.getItem('providerName') || 'testDemo'
        const loosingAccountID = localStorage.getItem('loosingAccountID') || 'AC1234'
        const portNo = localStorage.getItem('portNo') || '1234555'
        const selection = localStorage.getItem('selection') || 'IAS,NBICS,Option to port'

        const client = createCRMApiClient()
        const { postOTSAddEntities } = client.otsAddEntities

        try {
          // Check if the user confirmation is true before making the POST request
          if (sessionStorage.getItem('ots-user-confirmation') === 'true') {
            // Make the POST request to add entities
            await postOTSAddEntities({
              cartInstanceIdentifier,
              channelID,
              corelationID,
              loosingProviderName,
              selection,
              loosingAccountID,
              portNo,
            })
          } else {
            console.warn('User confirmation not provided. Skipping postOTSAddEntities.')
          }
        } catch (err) {
          console.error('Failed to add entities:', err)
        }
      }
    }

    if (currentStep === 'checkout-order-processing') {
      handleOrderProcessing()
    }

    if (currentStep === 'checkout-order-summary') {
      handleCheckoutSummary()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  useEffect(() => {
    if (currentStep === 'checkout-details') {
      if (premise?.address) {
        getOTSDirectory()
      }
    }
  }, [
    currentStep,
    premise,
  ])

  const saveFormDetails = (formData: OrderDetails) => {
    if (formData.data) {
      setOptions({
        ...options,
        data: {
          ...options?.data,
          ...formData.data,
        },
        promo: options?.promo ?? null,
      })
    } else {
      setOptions({
        ...options,
        ...formData,
      })
    }
  }

  const changeCollapsible = (nextCollapsible: StepType) => {
    if (nextCollapsible === 'checkout-payment' && premise?.type === 'Community') {
      router.push(getStepUrl('checkout-order-summary'), undefined, { shallow: true })
    } else {
      router.push(getStepUrl(nextCollapsible), undefined, { shallow: true })
    }
  }

  const client = createCRMApiClient()

  const getOTSDirectory = async () => {
    // Check if the OTSList data exists in localStorage
    const cachedOTSList = localStorage.getItem('OTSList')
    const cacheExpiryTime = localStorage.getItem('OTSListExpiryTime')

    // Check if cached data exists and is still valid (not expired)
    if (
      cachedOTSList &&
      cacheExpiryTime &&
      new Date()
        .getTime() < parseInt(cacheExpiryTime, 10) // Set radix to 10 for decimal conversion
    ) {
      // If data exists and is still valid, use it
      return JSON.parse(cachedOTSList)
    }

    // If data doesn't exist or is expired, make the API call
    try {
      const response = await client.otsDirectory.getOtsDirectoryList()
      const otsListData = response?.list

      if (otsListData) {
        // Save the response to localStorage as OTSList
        localStorage.setItem('OTSList', JSON.stringify(otsListData))

        // Set the expiration time for the cached data (e.g., 24 hours from now)
        const expiryTime = new Date()
        // eslint-disable-next-line no-mixed-operators
          .getTime() + 24 * 60 * 60 * 1000 // 24 hours in milliseconds
        localStorage.setItem('OTSListExpiryTime', expiryTime.toString())

        return otsListData
      }
    } catch (err) {
      console.error('Error fetching OTS Directory:', err)
    }

    return null
  }

  switch (currentStep) {
    case 'checkout-order-processing':
      return <OrderProcessing/>
    default:
      return (
        <>
          <ContainerWithPadding>
            {!specialContractLengthArray.includes(productContractLength) &&
            <Stepper currentStep={currentStep}/>}
          </ContainerWithPadding>
          <ContainerWithPadding>
            <Grid container justify="space-between" className={classes.container}>
              <Grid item xs={12} md={8} className={classes.leftContainer}>
                {currentStep === 'checkout-details' &&
                <Grid item container xs={12} className={classes.positionRelative}>
                  {!specialContractLengthArray.includes(productContractLength) &&
                  <Grid item xs={8} md={10}>
                    <Link href={getStepUrl('broadband', premise?.sprn)}>
                      <Box className={classes.backButton}>
                        <ArrowBack/>
                        <Typography variant="body1" color="primary">
                          Back to packages
                        </Typography>
                      </Box>
                    </Link>
                  </Grid>}
                  <Grid item xs={4} md={2} className={cx(specialContractLengthArray.includes(productContractLength) && classes.iconAbsolute)}>
                    <MediaImage
                      src="/60DaysFreeCancelationIcon.svg"
                      width={90}
                      height={90}
                      alt="60 days free cancelation"
                      center
                    />
                  </Grid>
                </Grid>}
                <Collapse
                  in={currentStep === 'checkout-details'}
                >
                  <Typography variant={isMobile ? 'h3' : 'h2'} color="primary">Your Details</Typography>
                  <PersonalDetailsForm
                    changeCollapsible={changeCollapsible}
                    saveFormDetails={saveFormDetails}
                    showOTS
                  />
                </Collapse>
                <Collapse
                  in={currentStep === 'checkout-one-touch-switching'}
                >
                  <Grid item xs={8} md={10}>
                    <Link href={getStepUrl('checkout-details', premise?.sprn)}>
                      <Box className={classes.backButton} mb={4}>
                        <ArrowBack/>
                        <Typography variant="body1" color="primary">
                          Back to details
                        </Typography>
                      </Box>
                    </Link>
                  </Grid>
                  <Box pb={2}>
                    <Typography variant={isMobile ? 'h3' : 'h2'} color="primary">Switching from your current provider</Typography>
                  </Box>
                  <Typography variant="body1" color="textPrimary" align="left">
                    We need to check a few details to confirm we can match the account and help you switch. You can usually find this information on a recent bill sent from your current provider.
                  </Typography>
                  <SwitchingSection changeCollapsible={changeCollapsible}/>
                </Collapse>
                <Collapse
                  in={currentStep === 'checkout-installation'}
                >
                  <Typography variant={isMobile ? 'h3' : 'h2'} color="primary">Installation</Typography>
                  <InstallationSection changeCollapsible={changeCollapsible} settings={generalSettings}/>
                </Collapse>
                {premise?.type !== 'Community' &&
                <Collapse
                  in={currentStep === 'checkout-payment'}
                >
                  <Typography variant={isMobile ? 'h3' : 'h2'} color="primary">Payment</Typography>
                  <PaymentDetails changeCollapsible={changeCollapsible}/>
                </Collapse>}
                <Collapse
                  in={currentStep === 'checkout-order-summary'}
                >
                  <Typography variant={isMobile ? 'h3' : 'h2'} color="primary">Review your order</Typography>
                  <SummarySection summaryData={data}/>
                </Collapse>
                <Hidden mdUp>
                  <Grid className={classes.directDebit} container alignItems="center" justify="center">
                    <Grid item xs={3} sm={2}>
                      <img src="/directDebitLogo.svg" alt="Direct debit"/>
                    </Grid>
                    <Grid item xs={9} sm={10}>
                      <Typography variant="body2">
                        Your payments are protected by the <a href="/legal-stuff#Direct-Debit-Guarantee" target="_blank" rel="noopener noreferrer">Direct Debit Guarantee</a>
                      </Typography>
                    </Grid>
                  </Grid>
                  {currentStep === 'checkout-order-summary' &&
                  <div className={classes.mobileOrderButton}>
                    {options.placeOrderError && <OrderError error={options.placeOrderError}/>}
                    <OrderButton id="CheckoutStep_OrderButton"/>
                  </div>}
                </Hidden>
              </Grid>
              <Grid item xs={12} md={3}>
                {(options.broadband || options.bundle) &&
                <Basket
                  broadband={options.broadband}
                  bundle={options.bundle}
                  timeslot={options.timeslot}
                  reachedInstallationStep={options.reachedInstallationStep}
                />}
              </Grid>
              <TimeoutModal changeCollapsible={changeCollapsible}/>
            </Grid>
          </ContainerWithPadding>
        </>
      )
  }
}
