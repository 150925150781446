/* eslint complexity: ["error", 100] */
import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Link, Typography,
} from '@material-ui/core'
import { cx } from 'linaria'
import { getOrderProducts } from '../../../../../../utils/getOrderProducts'
import {
  formattedFreePeriod,
  getFormattedMonthlyCost,
  getFormattedPrice,
  getFreePeriod,
  getStandardMonthlyCost,
  getPackageLabel,
  getPackageLength,
  getProductDiscountPeriod,
  getProductWithLongestFreePeriod,
  getStandartPriceIfDiscounted,
  getProductTerm,
  getTotalInstallationCost,
  renderDiscountPeriod,
  getInstallationCost,
  getMonthlyCost,
} from '../../../../../../utils/getProductDetails'
import MediaPageFlags from '../../../../../PaidMediaLandingPage/MediaPageHero/MediaPageVoucher'
import { useOrderContext } from '../../../../OrderContext'
import classes from '../Basket.styles'
import { AddOnBasket } from './AddOnBasket'
import { PriceBreakdown } from './PriceBreakdown'
import { ExpandMore } from '@material-ui/icons'
import { PackageProps } from '../../../../../Packages/Package'
import { toCurrencyString } from '../../../../../../utils'
import {
  FormContext, useForm,
} from 'react-hook-form'
import { OrderDetails } from '../../CheckoutStep'
import { PromoCode } from '../../PersonalDetailsForm/PersonalDetailsForm'
import getStepUrl from '../../../../getStepUrl'
import { PremiseContext } from '../../../../../PremiseTracker/PremiseContext'
import router from 'next/router'
import { HobsProduct } from '../../../../../../api/Packages'
import {
  BORDERS, general,
} from '../../../../../../styles/theme'
import { getButterPage } from '../../../../../../butter'

interface BasketDetailsProps {
  broadband: any;
  bundle?: any;
  isMiniBasket?: boolean;
  setShowErrorMiniBasket?: React.Dispatch<React.SetStateAction<boolean>>;
  hasTimeslot?: boolean;
}

export const basketRowProps = {
  display: 'flex',
  py: 2,
  borderTop: BORDERS.gray2,
}

export const productProps: object = {
  variant: 'body2',
  color: 'primary',
  className: classes.productLabel,
}

const installationRowProps: object = {
  variant: 'body2',
  color: 'primary',
  className: general.font.baseline.tight,
}

const BasketDetails = ({
  broadband, bundle, isMiniBasket, setShowErrorMiniBasket, hasTimeslot,
}: BasketDetailsProps) => {
  const {
    options, setOptions,
  } = useOrderContext()

  const { premise } = useContext(PremiseContext)

  const methods = useForm()

  const {
    voice, tv, wier, vas, discount, voucher_flags, promo, hide_strikethrough,
  } = options
  // 18 months condition -- start
  const [
    specialContractLengthArray,
    setSpecialContractLengthArray,
  ] = useState<string[]>([])
  const [
    isMonthlyCost,
    setIsMonthlyCost,
  ] = useState(false)

  useEffect(() => {
    async function getGeneralSettings() {
      const generalSettings: any = await getButterPage('general-settings', {})
      const specialContractLengthList = generalSettings.components.find((e: any) => e.type === 'general')?.special_contract_length
      const specialContractLengthArray = specialContractLengthList ? specialContractLengthList.split('|') : []
      setSpecialContractLengthArray(specialContractLengthArray)
    }

    getGeneralSettings()
  }, [])
  // 18 months condition -- end
  useEffect(() => {
    async function getGeneralSettings() {
      const generalSettings: any = await getButterPage('general-settings', {})
      const show_monthly_cost = generalSettings.components.find((e: any) => e.type === 'monthly_cost')?.show_monthly_cost
      setIsMonthlyCost(show_monthly_cost)
    }

    getGeneralSettings()
  }, [])
  function setVoiceOption(voice: HobsProduct | null) {
    setOptions({
      ...options,
      voice,
      hasSelectedNoVoice: !voice,
    })

    setShowErrorMiniBasket && setShowErrorMiniBasket(false)
  }

  function setTVOption(tv: HobsProduct | null) {
    setOptions({
      ...options,
      tv,
      hasSelectedNoTV: !tv,
    })

    setShowErrorMiniBasket && setShowErrorMiniBasket(false)
  }

  function setWIEROption(wier: HobsProduct | null) {
    setOptions({
      ...options,
      wier,
      hasSelectedNoWIER: !wier,
    })

    setShowErrorMiniBasket && setShowErrorMiniBasket(false)
  }

  function setVASOption(vas: HobsProduct | null) {
    setOptions({
      ...options,
      vas,
      hasSelectedNoVAS: !vas,
    })

    setShowErrorMiniBasket && setShowErrorMiniBasket(false)
  }

  const broadbandLength = broadband ? getPackageLength(broadband) : null
  const bundleLength = bundle ? getProductTerm(bundle) : null
  const discountLength = getProductDiscountPeriod(broadband)
  const broadbandIsFree = getFreePeriod(broadband) > 0
  const voiceIsFree = getFreePeriod(voice) > 0
  const tvIsFree = getFreePeriod(tv) > 0
  const wierIsFree = getFreePeriod(wier) > 0
  const vasIsFree = getFreePeriod(vas) > 0
  const orderSummaryClassName = cx(isMiniBasket && classes.noMaxHeight, classes.orderSummary, hasTimeslot && 'hasTimeslot')
  const showPromoCode = Boolean(!isMiniBasket && !broadbandIsFree && options.isDetailsStep)
  const voucher = isMiniBasket && options.offer === 'accepted' ? broadband?.voucher : voucher_flags!
  const orderProducts = getOrderProducts({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  })
  const totalInstalationCost = getTotalInstallationCost({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  })
  const totalCost = getMonthlyCost({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
    discount,
    promo,
  })
  const firstMonthPayment = `£${toCurrencyString(String(totalCost + totalInstalationCost))}`

  const saveFormDetails = (formData: OrderDetails) => {
    if (formData.data) {
      setOptions({
        ...options,
        data: {
          ...options?.data,
          ...formData.data,
        },
        discount: options?.discount ?? null,
      })
    } else {
      setOptions({
        ...options,
        ...formData,
      })
    }
  }

  const renderPackageName = (item: PackageProps) => {
    return (
      <Box display="flex" gridGap={8}>
        <Box flexGrow={1}>
          {item?.display_name && <Typography {...productProps}>{item?.display_name}</Typography>}
          <Typography variant="body2" className={general.font.weight.book}>{getPackageLabel(getProductTerm(item))}</Typography>
        </Box>
        <Box display="flex" gridGap={8}>
          {!hide_strikethrough &&
            <Typography variant="body2" className={general.font.baseline.tight}>
              <span className={classes.priceStrikeThrough}>{getStandartPriceIfDiscounted(item)}</span>
            </Typography>}
          <Typography {...productProps}>{getFormattedPrice(item)}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <FormContext {...methods}>
      <div className={orderSummaryClassName}>
        {voucher &&
          <MediaPageFlags vouchers={voucher} hideCountdown/>}
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h4" className={classes.basketTitle}>Your Basket</Typography>
          {!specialContractLengthArray.includes(String(broadbandLength)) &&
          <Link
            underline="always" color="secondary" onClick={() => {
              router.push(getStepUrl('broadband', premise?.sprn), undefined, {
                shallow: true,
              })
            }}
          >
            <Typography variant="body2" className={general.font.weight.semibold}>Edit</Typography>
          </Link>}
        </Box>
        <Box borderBottom={BORDERS.gray2} pb={2}>
          {renderPackageName(bundle || broadband)}
          {
            promo && (
              <div className={classes.importantInfo}>
                <Typography variant="body1" className={cx('italicfont', general.font.weight.bold)}>Promo {promo.name} applied</Typography>
              </div>
            )
          }
        </Box>
        <AddOnBasket product={voice} isFree={voiceIsFree} isMiniBasket={isMiniBasket} setOption={setVoiceOption}/>
        <AddOnBasket product={tv} isFree={tvIsFree} isMiniBasket={isMiniBasket} setOption={setTVOption}/>
        <AddOnBasket product={wier} isFree={wierIsFree} isMiniBasket={isMiniBasket} setOption={setWIEROption}/>
        <AddOnBasket product={vas} isFree={vasIsFree} isMiniBasket={isMiniBasket} setOption={setVASOption}/>
        <div className={classes.basketExpansion}>
          <Box flexGrow={1} display="flex" justifyContent="space-between" pt={2} pb={2}>
            <Typography {...productProps} gutterBottom>Set up fee</Typography>
            <Typography
              {...installationRowProps}
              className={cx('italicfont', general.font.weight.semibold)}
            >
              £{(() => {
                const cost = getInstallationCost(
                  broadband || bundle || wier || vas || voice || tv,
                  Boolean(wier || vas || voice || tv),
                )
                return cost === '0.00' ? '0' : cost
              })()}
            </Typography>

          </Box>
          {/* <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMore/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={classes.expansionPanelDetails}
            >
              {broadband &&
              <Box display="flex" gridGap={16}>
                <Box flexGrow={1}>
                  <Typography {...installationRowProps}>Broadband Installation & Setup</Typography>
                </Box>
                <Typography id="setupFeeExp" {...installationRowProps}>£{getInstallationCost(broadband)}</Typography>
              </Box>}
              {bundle &&
              <Box display="flex" gridGap={16}>
                <Box flexGrow={1}>
                  <Typography {...installationRowProps}>Broadband Bundle Installation & Setup</Typography>
                </Box>
                <Typography id="setupFeeBundle" {...installationRowProps}>£{getInstallationCost(bundle)}</Typography>
              </Box>}
              {wier &&
              <Box display="flex" gridGap={16}>
                <Box flexGrow={1}>
                  <Typography {...installationRowProps}>Wifi In Every Room Installation & Setup</Typography>
                </Box>
                <Typography id="setupFeeWIER" {...installationRowProps}>£{getInstallationCost(wier, true)}</Typography>
              </Box>}
              {vas &&
              <Box display="flex" gridGap={16}>
                <Box flexGrow={1}>
                  <Typography {...installationRowProps}>VAS Installation & Setup</Typography>
                </Box>
                <Typography id="setupFeeVAS" {...installationRowProps}>£{getInstallationCost(vas, true)}</Typography>
              </Box>}
              {voice &&
              <Box display="flex" gridGap={16}>
                <Box flexGrow={1}>
                  <Typography {...installationRowProps}>Phone Installation & Setup</Typography>
                </Box>
                <Typography id="setupFeeCalls" {...installationRowProps}>£{getInstallationCost(voice, true)}</Typography>
              </Box>}
              {tv &&
              <Box display="flex" gridGap={16}>
                <Box flexGrow={1}>
                  <Typography {...installationRowProps}>TV Installation & Setup</Typography>
                </Box>
                <Typography id="setupFeeTV" {...installationRowProps}>£{getInstallationCost(tv, true)}</Typography>
              </Box>}
            </ExpansionPanelDetails>
          </ExpansionPanel> */}
        </div>
        {
          discount ? (
            <Box {...basketRowProps}>
              <Box flexGrow={1}>
                <Typography {...productProps}>
                  Month 1 payment
                </Typography>
                {promo &&
                  <div className={classes.importantInfo}>
                    <Typography variant="body1" className={cx('italicfont', general.font.weight.bold)} color="primary">
                      {isMonthlyCost ?
                        <>
                          <strong>{
                            getFormattedMonthlyCost({
                              broadband,
                              bundle,
                              voice,
                              tv,
                              wier,
                              vas,
                              discount,
                            })
                          }/month
                          </strong> after {renderDiscountPeriod(promo)}
                        </> :
                        <>
                          <strong>
                            Free broadband {' '}
                          </strong>
                          for {' '}
                          {renderDiscountPeriod(promo)}
                        </>}

                    </Typography>
                    {discountLength && ((broadbandLength && discountLength < broadbandLength) || (bundleLength && discountLength < bundleLength)) &&
                      <div className={classes.importantInfo}>
                        <Typography variant="body1" className={cx('italicfont', general.font.weight.bold)} color="primary">
                          {
                            isMonthlyCost ?
                              <>
                                <strong>
                                  {getStandardMonthlyCost({
                                    broadband,
                                    bundle,
                                    voice,
                                    tv,
                                    wier,
                                    vas,
                                  })}/month
                                </strong> after {discountLength} months
                              </> :
                              <>
                                Free broadband {' '}
                                <strong>
                                  {discountLength} months
                                </strong>
                              </>
                          }

                        </Typography>
                      </div>}
                  </div>}
              </Box>
              <Typography {...productProps}>{firstMonthPayment}</Typography>
            </Box>
          ) : (
            <>
              <Box {...basketRowProps}>
                <Box flexGrow={1}>
                  <Typography {...productProps}>
                    Month 1 payment
                  </Typography>
                  {(broadbandIsFree || voiceIsFree || tvIsFree || wierIsFree || vasIsFree) && // TO DO - broadband without discount but is free? do we really need this code? To investigate PriceBreakdown
                  <>
                    <Typography variant="body2" color="primary">Promotion applies for the first {formattedFreePeriod(getProductWithLongestFreePeriod(orderProducts))}</Typography>
                    <PriceBreakdown broadband={broadband} bundle={bundle} voice={voice} tv={tv} wier={wier} vas={vas}/>
                  </>}
                </Box>
                <Typography {...productProps}>
                  {firstMonthPayment}
                </Typography>
              </Box>
              {promo &&
                <Box mb={2}>
                  <Typography variant="subtitle2" className={classes.importantInfo} color="primary">
                    {isMonthlyCost ?
                      <>
                        <strong>{
                          getFormattedMonthlyCost({
                            broadband,
                            voice,
                            tv,
                            wier,
                            vas,
                            discount,
                          })
                        }/month
                        </strong> after {renderDiscountPeriod(promo)}
                      </> :
                      <>

                        <strong>
                          Free broadband {' '}
                        </strong>
                        for
                        {' '}
                        {renderDiscountPeriod(promo)}
                      </>}
                  </Typography>
                </Box>}
            </>
          )
        }
        {showPromoCode &&
        <Box className={classes.basketExpansion} borderTop={BORDERS.gray2}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMore/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expansionPanelTight}
            >
              <Typography {...productProps}>Promo Code</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PromoCode
                savedPromo={options ? options.promo : null}
                saveFormDetails={saveFormDetails}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>}
      </div>
    </FormContext>
  )
}

export default BasketDetails
