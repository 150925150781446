import { css } from 'linaria'
import theme from '../../../../../styles/theme'

export const classes = {
  modal: css`
    position: absolute;
    width: ${theme.breakpoints.width('md')}px;
    max-width: 70%;
    border: 2px solid #000;
    box-shadow: ${theme.shadows[5]};
    padding: ${theme.spacing(2, 4, 4)};
    background-color: ${theme.palette.background.paper};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  `,
  button: css`
  font-weight: ${theme.typography.fontWeightBold!} !important;
  `,
  backdrop: css`
    > div:first-of-type {
      pointer-events: none;
      cursor: default;
    }
  `,
  icon: css`
    &.MuiSvgIcon-root {
      font-size: 5em;
    }
  `,
  bodyText: css`
    &.MuiTypography-root {
      font-size: ${theme.typography.h3.fontSize!}px;
    }
  `,
}
