import { css } from 'linaria'
import fontSizes from '../../styles/fontSizes'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  wrapper: css`
    width: 100%;
    max-width: ${theme.spacing(106.25)}px !important;
    .MuiTypography-h1 {
       font-weight: ${theme.typography.fontWeightBold!};
       font-size: ${fontSizes.h2};
       color: ${COLORS.primary};
       margin: ${theme.spacing(2, 0)};
    }
    .MuiTypography-body2 {
      font-style: normal;
      font-weight: ${theme.typography.fontWeightRegular!};
      font-size: ${fontSizes.body1};
      color: ${COLORS.bodyTextPrimary};
    }
    textarea {
      box-sizing: border-box;
      width: 100%;
      max-width: ${theme.spacing(100)}px;
      height: ${theme.spacing(15.62)}px;
      padding: ${theme.spacing(1.25)}px;
      background: #FFFFFF;
      border: 1px solid #DCDCDC;
      border-radius: 20px;
      word-break: break-word;
      resize: none;
      margin-top: ${theme.spacing(3)}px;
    }
    .MuiInputLabel-root {
      font-size: 1.18rem;
      font-weight: ${theme.typography.fontWeightBold!};
      color: ${COLORS.primary}
    }
    .MuiBox-root {
      max-width: 100%;
    }
    button[type='submit'] {
      .MuiTypography-root {
        font-weight: ${theme.typography.fontWeightBold!};
      }
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }
    }
 `,
  fieldClass: css`
    width: 100%;
   .MuiInput-underline:before {
     border-bottom-color: #CCCCCC;
   }
`,
  checkboxesContainer: css`
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightBold!};
      font-size: 1.18rem;
      color: ${COLORS.primary}
    }
    .MuiSvgIcon-root {
      width: ${theme.spacing(4.37)}px;
      height: ${theme.spacing(4.37)}px;
    }
`,
  selectInput: css`
    width:100%;
    .MuiSelect-select {
      background: ${COLORS.smoke};
      border-radius: 20px;
    }
    .MuiSelect-select.MuiSelect-select {
      padding: ${theme.spacing(1.62)}px;
    }
    .Mui-focused {
      .MuiSelect-select {
        border-radius: 20px;
      }
    }
    .MuiInputLabel-formControl {
      font-size: 1.18rem;
      transform: unset;
    }
    fieldset {
      border: none;
    }
    .MuiInput-underline:before{
      display: none;
    }
    .MuiInput-underline:after{
      display: none;
    }
    .MuiSelect-icon {
      right: ${theme.spacing(1.25)}px;
    }
    .MuiInput-root {
      margin-top: ${theme.spacing(2)}px;
    }
`,
  error: css` 
    &.MuiPaper-root {
        color: ${theme.palette.error.main};
        margin: 0 auto;
        margin-top: ${theme.spacing(1)}px;
        margin-bottom: ${theme.spacing(6)}px;
        padding: ${theme.spacing(3)}px;
        border: 3px solid ${COLORS.gray2};
    }
    .MuiTypography-body2 {
      color: ${theme.palette.error.main};
    }
  `,
  reasonError: css` 
    label {
      color: ${theme.palette.error.main} !important;
    }
    p {
      font-size: ${fontSizes.body3} !important;
      margin-top: 5px;
      color: ${theme.palette.error.main} !important;
    }
    .MuiInputBase-input {
      border: 1px solid ${theme.palette.error.main};
    }
  `,
  loading: css`
    margin-left:${theme.spacing(3)}px;
    vertical-align: middle;
  `,
}

export default classes
