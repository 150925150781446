import React, { useContext } from 'react'
import {
  useForm, FormContext,
} from 'react-hook-form'
import {
  Container, Box, Grid, Typography, Link, Button, CircularProgress,
} from '@material-ui/core'
import MaskInput from 'react-maskinput'
import TextInput from '../../../../TextInput/TextInput'
import FormHeader from '../../../../FormHeader/FormHeader'
import createCRMApiClient from '../../../../../api/CRMApi'
import { useOrderContext } from '../../../OrderContext'
import { useGeneralContext } from '../../../../GeneralContext/GeneralContext'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'
import logger from '../../../../../utils/logger'
import classes from './PaymentDetails.styles'
import { ChangeCollapsible } from '../CheckoutStep'
import OrderError from '../OrderError/OrderError'
import { JsonObject } from 'type-fest'
import CountdownBanner from '../CountdownBanner/CountdownBanner'
import { formatInstallationAddress } from '../InstallationSection/InstallationSection'
import { useRouter } from 'next/router'
import { PremiseContext } from '../../../../PremiseTracker/PremiseContext'

export interface PaymentData extends JsonObject{
  accountName: string;
  sortCode: string;
  accountNumber: string;
}

interface FormData {
  accountHolderName: string;
  accountNumber: string;
  sortCode: string;
}

function isNotMetroBankAccount(value: string): boolean | string {
  return value === '23-05-80' ? 'Unfortunately, we don’t accept Metro Bank card details. You can continue with alternative bank details.' : true
}

export default function PaymentDetails({ changeCollapsible }: { changeCollapsible: ChangeCollapsible}) {
  const methods = useForm<FormData>()
  const { premise } = useContext(PremiseContext)
  const {
    options, setOptions,
  } = useOrderContext()

  const {
    handleSubmit, formState,
  } = methods

  const { isSubmitting } = formState

  const {
    cartInstanceIdentifier, data, channel,
  } = options

  const {
    email, phone, firstName, lastName, sprn,
  } = data

  const {
    generalOptions,
  } = useGeneralContext()

  const {
    customerSegment, customerCategory,
  } = generalOptions

  const router = useRouter()
  const buttonSubmitText = 'Proceed to order summary'

  const onSubmit = handleSubmit(async ({
    accountNumber, sortCode, accountHolderName,
  }) => {
    marketingEvents.cta({
      name: buttonSubmitText,
      router,
      options,
    })

    if (!sortCode || !accountNumber || !accountHolderName) {
      const errorMsg = 'There was an error due to missing fields, please check your bank details'
      logger.error('Missing bank details', 'missing bank detail params')
      marketingEvents.error(options, 'ERR-PAY-1', 'Missing bank details', errorMsg)
      setOptions({
        ...options,
        paymentError: errorMsg,
      })
      return
    }

    if (sortCode === '23-05-80') {
      setOptions({
        ...options,
        paymentDetails: {
          accountName: accountHolderName,
          accountNumber,
          sortCode,
        },
        paymentError: '',
      })
      marketingEvents.onCheckoutSection('summary')
      changeCollapsible('checkout-order-summary')
      return
    }

    const client = createCRMApiClient()

    const bankDetails = await client.payment.verifySortCode(
      sortCode,
      accountNumber,
    )

    const scrollErrorIntoView = () => {
      if (!window) {
        return
      }

      const error = document.querySelector('#payment-error')
      const scrollToError = () => {
        error && error.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }

      setTimeout(scrollToError, 250)
    }

    const bacsDrDisallowed = bankDetails?.BankValUK?.bacsdrdisallowed?.toLowerCase() === 'DR'
    if ('error' in bankDetails || !bankDetails?.BankValUK?.ownbc || bacsDrDisallowed) {
      logger.error(bankDetails.message, bankDetails.error)
      setOptions({
        ...options,
        paymentError: `${bacsDrDisallowed ?
          'Unsupported account number, please provide another bank account number' :
          'There was an error confirming your bank account, please check your bank details and contact customer service at 0800 082 0770.'}`,
      })
      scrollErrorIntoView()
      return
    }

    setOptions({
      ...options,
      paymentDetails: {
        accountName: accountHolderName,
        accountNumber,
        sortCode,
      },
      paymentError: '',
    })

    let addressSplited: string[] = []
    if (data.address) {
      addressSplited = formatInstallationAddress(data.address)
        .split(',')
    }

    const borough = premise?.borough!
    const partnerID = premise?.coverage_indicator! === 'B' ? 'box' : 'cfl'

    await client.cart.setCartCustomerAccountWrapper({
      channel,
      cartInstanceIdentifier,
      customerCategory,
      customerSegment,
      primaryEmailID: email,
      telephoneNumber: phone,
      addressSplited,
      borough,
      givenName: firstName,
      familyName: lastName,
      accountHolderName,
      bankCode: sortCode.replace(/-/g, ''),
      accountNumber,
      sprn,
      partnerID,
    })

    marketingEvents.onCheckoutOption(5)
    marketingEvents.onCheckoutSection('summary')
    changeCollapsible('checkout-order-summary')
  })

  const { paymentError } = options

  return (
    <FormContext {...methods}>
      <Container>
        <Box pt={3} pb={6}>
          {options.countDownTimerStart &&
          <CountdownBanner countDownTimerStart={options.countDownTimerStart}/>}
          <Box mb={3} id="direct_debit_message">
            <Typography component="p" color="primary">Please Note: Direct Debit must be from a UK current account. Cash, Deposit, Savings, or overseas accounts do NOT support Direct Debit.</Typography>
          </Box>
          {paymentError && <OrderError error={paymentError}/>}
          <form onSubmit={onSubmit}>
            <FormHeader title="Direct Debit" withLegend={false}/>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <TextInput
                  label="Account Holder Name"
                  gridWidth={4}
                  validation={{
                    required: 'Account holder name is required.',
                    pattern: {
                      value: /^(?! )[A-Za-zÀ-ÖØ-öø-ÿ'`-]+(?: [A-Za-zÀ-ÖØ-öø-ÿ'`-]+)*(?<! )$/,
                      message: 'Invalid account holder name. Please use letters without numbers, and ensure no leading or trailing spaces.',
                    },
                  }}
                />
              </Grid>
              <TextInput
                label="Account Number" gridWidth={4}
                inputProps={{
                  maxLength: 8,
                }}
                validation={{
                  maxLength: {
                    value: 8,
                    message: 'Invalid account number. Please use a valid UK Account Number. Number should be 8 digits.',
                  },
                  minLength: {
                    value: 8,
                    message: 'Invalid account number. Please use a valid UK Account Number. Number should be 8 digits.',
                  },
                  pattern: {
                    value: /^(?!00000000)\d{8}$/,
                    message: 'Invalid account number. Please use a valid UK Account Number. Number should be 8 digits.',
                  },
                }}
              />
              <TextInput
                label="Sort Code" gridWidth={4}
                customInputComponent={SortCodeMask} placeholder="00-00-00" validation={{
                  maxLength: {
                    value: 8,
                    message: 'Invalid sort code',
                  },
                  minLength: {
                    value: 8,
                    message: 'Invalid sort code',
                  },
                  validate: isNotMetroBankAccount,
                }}
              />
              <Box px={3}>
                <Box pb={1}>
                  <Typography variant="overline" component="div" color="primary">Your payments are protected by the <Link underline="always" href="/legal-stuff#Direct-Debit-Guarantee" target="_blank" rel="noopener noreferrer">Direct Debit Guarantee</Link></Typography>
                </Box>
                <Typography variant="overline" component="div" color="primary">The cooling-off cancellation period is 14 days from the order confirmation date.</Typography>
              </Box>
              <Grid item xs={12} className={classes.submit}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  id="step-3"
                >
                  <Typography className={classes.button} noWrap>{ buttonSubmitText }</Typography>
                  {isSubmitting && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </FormContext>
  )
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function SortCodeMask(props: TextMaskCustomProps) {
  const {
    inputRef, ...other
  } = props

  return (
    <MaskInput
      {...other}
      maskChar="-"
      getReference={inputRef}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask="00-00-00"
      // @ts-ignore
      size={8}
    />
  )
}
