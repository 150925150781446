import {
  format, parseISO,
} from 'date-fns'
import {
  ProductOffering,
  ProductSpec,
  ProductSpecCharacteristic,
  ProductSpecCharacteristicValue,
  HobsProduct,
} from '../api/Packages'
import { PackageProps } from '../components/Packages/Package'
import { ProductProps } from '../components/Products/Product'
import AES from 'crypto-js/aes'
import { enc } from 'crypto-js'
import env from './env'
import { HobsServiceType } from '../api/Products'
import { getButterPage } from '../butter'

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function toFormattedDate(dateISOString: string) {
  return format(parseISO(dateISOString), 'dd/MM/yy')
}

export const formatDate = (dateString: string) => {
  const dateObject = new Date(dateString)
  const day = dateObject.getDate()
  const month = dateObject.toLocaleString('en-US', { month: 'long' })
  const year = dateObject.getFullYear()
  return `${day} ${month} ${year}`
}

export function toCurrency(num: string): number {
  const parsedNum = parseFloat(num)

  const parsedNumFixed = parsedNum % 1 === 0 ? parsedNum :
    parsedNum.toFixed(2)

  return typeof parsedNumFixed === 'number' ? parsedNumFixed : 0
}

export function toCurrencyString(num: string) {
  const parsedNum = parseFloat(num)

  return (parsedNum % 1 === 0 ? parsedNum :
    parsedNum.toFixed(2)).toString()
}

export const nameRegex = /^(?!.*(?<character>[a-zA-Z\s-])\k<character>{2})(?!.*\s{2})[a-zA-Z\s-]+$/
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const phoneRegex = /^[0]\S(?!000000000)\d{9}$/
export const addressRegex = /^(?!^\d+\s*$)(?!^[\s\d]*$)(?!.*(?<char>[a-zA-Z0-9,.-])\k<char>{3})(?!.*\s{2})[a-zA-Z0-9\s,.-]+$/
export const accountRegexCFL = /^[A-Za-z]{1,2}-?\d{4,10}$/
export const accountRegex = /^(?!.*(?<char>.)\k<char>{10})(?!.*[-_#].*[-_#])[A-Za-z\d_#-]{3,25}$/

export const splitBullets = (bullets: string): Array<string> => bullets?.split(/\r?\n/)

export const toTitleCase = (str: string): string =>
  str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0)
      .toUpperCase() + word.slice(1))
    .join(' ')

export const addProperty = (key: string, value: any) => {
  if (value && (Object.keys(value).length !== 0 || (typeof value === 'boolean' && Object.keys(value)))) {
    return {
      [key]: value,
    }
  }

  return null
}

export const getRouterName = (product: HobsProduct) => {
  return product?.productOffering
    ?.find((product: ProductOffering) => product.productOfferingId === 'RES_Router')
    ?.productSpec?.find((spec: ProductSpec) => spec.productSpecID === 'RES_Router')
    ?.productSpecCharacteristic?.find((specChar: ProductSpecCharacteristic) => specChar.name === 'Router_Manufacturer')
    ?.productSpecCharacteristicValue.find((specCharVal: ProductSpecCharacteristicValue) => specCharVal.value && specCharVal.default)
    ?.value
}

export const getSingleProduct = (data: PackageProps[], id: string) => {
  return data.find((e: PackageProps) => e.productOfferingId === id)
}

export const getHybridPackages = (dataCRM: PackageProps[], dataCMS: PackageProps[]) => {
  const hybridPackages = [] as PackageProps[]
  dataCMS.forEach((cms: PackageProps) => {
    dataCRM && dataCRM.forEach((crm: PackageProps) => {
      if (cms.id === crm.productOfferingId) {
        hybridPackages.push({
          ...addProperty('display_name', cms.display_name),
          ...addProperty('display_subtitle', cms.display_subtitle),
          ...addProperty('usage_description', cms.usage_description),
          ...addProperty('button', cms.button),
          ...addProperty('button_secondary', cms.button_secondary),
          ...cms?.simple_bullets && addProperty('simple_bullets', cms?.simple_bullets),
          ...cms?.bullets_back && addProperty('bullets_back', cms?.bullets_back),
          ...cms?.display_image && addProperty('display_image', cms?.display_image),
          ...addProperty('bullets_with_icons', cms.bullets_with_icons),
          ...addProperty('hide', cms.hide),
          ...addProperty('name_first_line', cms.name_first_line),
          ...addProperty('name_second_line', cms.name_second_line),
          ...addProperty('strikethrough_name', cms.strikethrough_name),
          ...addProperty('flag', cms.flag),
          ...addProperty('flip', cms.flip),
          ...addProperty('align', cms.align),
          ...addProperty('title', cms.title),
          ...addProperty('footnote', cms.footnote),
          ...addProperty('anchor', cms.anchor),
          ...addProperty('type', cms.type),
          ...addProperty('service_type', cms.service_type),
          ...addProperty('usp_description', cms.usp_description),
          ...addProperty('out_of_contract_copy', cms.out_of_contract_copy),
          ...addProperty('voucher', cms.voucher),
          ...addProperty('price_guidance', cms.price_guidance),
          ...addProperty('price', cms.price),
          ...addProperty('old_price', cms.old_price),
          ...(cms.service_type === HobsServiceType.BUNDLE && addProperty('wier_id', cms.wier_id)),
          ...crm,
        })
      }
    })
  })

  return hybridPackages
}

export const getHybridProducts = (dataCRM: ProductProps[], dataCMS: ProductProps[]) => {
  const hybridProducts = [] as ProductProps[]
  dataCMS.forEach((cms: ProductProps) => {
    dataCRM && dataCRM.forEach((crm: ProductProps) => {
      if (cms.id === crm.productOfferingId) {
        hybridProducts.push({
          ...cms?.bullets && addProperty('bullets', cms?.bullets),
          ...addProperty('image', cms.image),
          ...addProperty('button', cms.button),
          ...addProperty('title', cms.title),
          ...addProperty('subtitle', cms.subtitle),
          ...addProperty('display_name', cms.display_name),
          ...addProperty('display_image', cms.display_image),
          ...addProperty('more_info_modal', cms.more_info_modal),
          ...addProperty('hide_for_box', cms.hide_for_box),
          ...addProperty('service_type', cms.service_type),
          ...addProperty('anchor', cms.anchor),
          ...addProperty('usp_description', cms.usp_description),
          ...addProperty('contract_length', cms.contract_length),
          ...crm,
        })
      }
    })
  })

  return hybridProducts
}

export const getHybridArray = (dataCRM: HobsProduct[], dataCMS: HobsProduct[], props: {}) => {
  const hybridProducts = [] as Array<{}>
  dataCMS.forEach((cms: HobsProduct) => {
    dataCRM && dataCRM.forEach((crm: HobsProduct) => {
      if (cms.id === crm.productOfferingId) {
        hybridProducts.push(props)
      }
    })
  })

  return hybridProducts
}

const key = enc.Hex.parse(env.Cryptokey)
const iv = enc.Hex.parse(env.CryptoKeyIv)

export const encryptString = (string: string) => {
  const encrypted = AES.encrypt(string, key, { iv })
  return encodeURIComponent(encrypted.toString())
}

export const decryptString = (string: string) => {
  try {
    const decodedStr = decodeURIComponent(string)
    const decrypted = string.length > 24 ? AES.decrypt(decodedStr, env.EncryptSecretPhrase) : AES.decrypt(decodedStr, key, { iv })
    return decrypted.toString(enc.Utf8) || string
  } catch (err) {
    return err
  }
}

export const scrollStepIntoView = (id: string, time?: number) => {
  const openCollapsible: HTMLElement | null = document.querySelector(id)

  if (openCollapsible) {
    const waitOnCollapsible = setTimeout(() => {
      jumpToElement(openCollapsible, true)
    }, time)

    return () => {
      clearTimeout(waitOnCollapsible)
    }
  }

  return null
}

export const jumpToElement = (htmlElement: HTMLElement, alignToTop: boolean) => {
  document.body.style.scrollBehavior = 'auto'
  htmlElement.scrollIntoView(alignToTop)
  document.body.style.scrollBehavior = 'smooth'
}

export const handlePhoneChange = (e: Event) => {
  const target = e?.target as HTMLInputElement
  if (target.value.startsWith('+')) {
    target.value = `00${target.value.slice(1, target.value.length)}`
  }

  target.value = target.value.replace(/\+/g, '')
}

export const specialCharactersSanitizer = (e: Event) => {
  const target = e?.target as HTMLInputElement

  target.value = target.value.replace(/[~`!@#$%^&*()+={}[\];:'"<>.,/\\?\-_]/g, '')
}

export const handleKeyDown = (e: KeyboardEvent) => {
  const key = e.key
  if (!((key >= '0' && key <= '9') || key === '+' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Delete' || key === 'Backspace' || key === 'Tab' || key === 'Enter')) {
    e.preventDefault()
  }
}

export const handleCopyPasteEvents = (e: Event) => {
  e.preventDefault()
}

export const removeSpecialCharacters = (input?: string) => {
  const pattern = /[^\w\s]/g
  return input ? input.replace(pattern, '') : ''
}

async function getGeneralSettings() {
  const generalSettings: any = await getButterPage('general-settings', {})
  const cycleComponent = generalSettings.components.find((e: any) => e.type === 'billing_cycle')

  return Object.fromEntries(
    Object.entries(cycleComponent)
      .filter(([key]) => key !== 'type'),
  )
}

export async function getCustomerBillCycleId(): Promise<string> {
  const billCycles = [
    {
      id: 'M03',
      day: 3,
    },
    {
      id: 'M05',
      day: 5,
    },
    {
      id: 'M07',
      day: 7,
    },
    {
      id: 'M12',
      day: 12,
    },
    {
      id: 'M14',
      day: 14,
    },
    {
      id: 'M16',
      day: 16,
    },
    {
      id: 'M18',
      day: 18,
    },
    {
      id: 'M20',
      day: 20,
    },
    {
      id: 'M22',
      day: 22,
    },
    {
      id: 'M24',
      day: 24,
    },
    {
      id: 'M26',
      day: 26,
    },
    {
      id: 'M28',
      day: 28,
    },
  ]

  const settings = await getGeneralSettings()

  const availableBillCycles = billCycles.filter(cycle => settings[cycle.id.toLowerCase()] === true)
  const today = new Date()
  const futureDate = new Date(today)
  futureDate.setDate(today.getDate() + 10)

  for (const element of availableBillCycles) {
    if (futureDate.getDate() <= element.day) {
      return element.id
    }
  }

  return availableBillCycles.length > 0 ? availableBillCycles[0].id : billCycles[0].id
}
