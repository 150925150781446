import React, {
  useEffect, useState,
} from 'react'
import {
  Box, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Typography,
} from '@material-ui/core'
import TextInput from '../TextInput/TextInput'
import {
  useForm, FormContext, Controller,
} from 'react-hook-form'
import classes from './ContactUsForm.styles'
import StyledButton from '../StyledButton/StyledButton'
import logger from '../../utils/logger'
import createCRMApiClient from '../../api/CRMApi'
import {
  ExperianEmail, ExperianPhone,
} from '../../api/Experian'
import { ExperianOptions } from '../ExperianValidation/ExperianValidation'
import { experianStore } from '../../sessionStorage'
import { PersonalDetailsFormData } from '../NewOrderFlow/steps/CheckoutStep/PersonalDetailsForm/PersonalDetailsForm'
import { arraysEqual } from '../../utils/arraysEqual'
import {
  specialCharactersSanitizer, handleKeyDown, handlePhoneChange,
  phoneRegex,
  nameRegex,
  addressRegex,
} from '../../utils'
import { cx } from 'linaria'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useOrderContext } from '../NewOrderFlow/OrderContext'

const contactReasonsList = [
  'No Internet Connection',
  'Billing or Contract Query',
  'Technical Issue',
  'Appointment Query',
  'Business Query',
  'Voice or TV Products',
  'Upgrade or Renewals',
  'Moving Home',
  'Freeze Contract',
  'Account Transfer or Bereavement',
  'Change or Add Contact Details',
  'My account',
  'Referrals or Vouchers',
  'Complaint',
  'Cancellation Request',
  'Marketing Preferences',
  'Issue Not Listed',
]

const complaintSubreasonsList = [
  'Installation',
  'Provide feedback on staff',
  'About My Contract',
  'Free Add on Promised',
  '60 Days Satisfaction Guarantee',
  'Broadband service',
  'TV & Voice',
  'Trust Pilot',
  'Billing',
]

const cancellationSubreasonsList = [
  'Technical Reason',
  'Billing Reason',
  'Installation',
  'Moving to another provider',
  'Unhappy with contract',
  'Financial hardship',
  'Unhappy with recent appointment',
]

export interface ContactUsFormProps {
  title?: string;
  sub_title?: string;
}

export default function ContactUsForm(props: ContactUsFormProps) {
  const {
    title, sub_title,
  } = props

  const {
    options,
  } = useOrderContext()

  const methods = useForm<PersonalDetailsFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  const {
    handleSubmit, control, getValues, triggerValidation, formState,
  } = methods
  const { isSubmitting } = formState

  const [
    checked,
    setChecked,
  ] = useState<boolean>(false)

  const [
    error,
    setError,
  ] = useState<boolean>(false)

  const [
    reasonValue,
    setReasonValue,
  ] = useState<string>('')

  const [
    subreasonValue,
    setSubreasonValue,
  ] = useState<string>('')

  const [
    showSubreasons,
    setShowSubreasons,
  ] = useState<boolean>(false)

  const [
    subreasonsList,
    setSubreasonsList,
  ] = useState<string[]>([])

  const [
    reasonError,
    setReasonError,
  ] = useState<boolean>(false)

  const [
    subreasonError,
    setSubreasonError,
  ] = useState<boolean>(false)

  const [
    customBox,
    setCustomBox,
  ] = useState<boolean>(false)

  const [
    experianData,
    setExperianData,
  ] = useState<ExperianOptions | null>()

  useEffect(() => {
    (async () => {
      const response = await experianStore.get()
      if (response) {
        setExperianData(response)
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      await setReasonError(false)
      await setSubreasonError(false)
      await setCustomBox(false)
      await setShowSubreasons(false)

      switch (reasonValue) {
        case 'Issue Not Listed':
          setCustomBox(true)
          break
        case 'Complaint':
          setShowSubreasons(true)
          setSubreasonsList(complaintSubreasonsList)
          break
        case 'Cancellation Request':
          setShowSubreasons(true)
          setSubreasonsList(cancellationSubreasonsList)
          break
        default:
          break
      }
    })()
  }, [reasonValue])

  useEffect(() => {
    const requiredFields: string[] = [
      'firstName',
      'lastName',
      'enquiryDetails',
      'yourEmailAddress',
      'contactNumber',
      'reasonForContacting',
      'subreason',
    ]
    const completedFields: string[] = [...formState.dirtyFields]
    arraysEqual(completedFields, requiredFields)
  }, [getValues()])

  const triggerEmailVerification = () => {
    const { confirmEmail } = getValues()
    if (confirmEmail) {
      triggerValidation('confirmEmail')
    }
  }

  const validateExperianEmail = async (input: string) => {
    const client = createCRMApiClient()

    if (!experianData?.email_validation) {
      return
    }

    const validationResponse: ExperianEmail = await (async () => {
      try {
        return await client.experian.validateEmail(input)
      } catch (err) {
        marketingEvents.error(options, 'ERR-CONTACT-US-4', 'Contact Us Form - Email validation', err.message)
        return err.message
      }
    })()

    for (const el of experianData?.email_validation_cases) {
      if (validationResponse?.result?.confidence === el.confidence) {
        return
      }
    }

    marketingEvents.error(options, 'ERR-CONTACT-US-4', 'Contact Us Form - Email validation', experianData?.email_error)
    return experianData?.email_error
  }

  const validateExperianPhone = async (input: string) => {
    const client = createCRMApiClient()

    if (!experianData?.phone_validation) {
      return
    }

    const validationResponse: ExperianPhone = await (async () => {
      try {
        return await client.experian.validatePhone(input)
      } catch (err) {
        marketingEvents.error(options, 'ERR-CONTACT-US-5', 'Contact Us Form - Phone validation', err.message)
        return err.message
      }
    })()

    for (const el of experianData?.phone_validation_cases) {
      if (validationResponse?.result?.confidence === el.confidence) {
        return
      }
    }

    marketingEvents.error(options, 'ERR-CONTACT-US-5', 'Contact Us Form - Phone validation', experianData?.phone_error)
    return experianData?.phone_error
  }

  const onSubmit = handleSubmit(async (data: any) => {
    if (!reasonValue || reasonValue === 'Please select a reason') {
      setReasonError(true)
      return
    }

    setReasonError(false)

    if (showSubreasons && (!subreasonValue || subreasonValue === 'Please select a more detailed reason')) {
      setSubreasonError(true)
      return
    }

    setSubreasonError(false)

    const client = createCRMApiClient()

    const {
      firstName,
      lastName,
      enquiryDetails,
      yourEmailAddress,
      addressLine_1,
      addressLine_2,
      addressLine_3,
      townOrCity,
      postcode,
      contactNumber,
      reasonForContacting,
      pleaseWriteYourReason,
      subreason,
    } = data

    const customerCheck: any = await client.contactUs.customerCheck(
      yourEmailAddress,
      contactNumber,
    )

    const reason = pleaseWriteYourReason || reasonForContacting
    const accountId = customerCheck?.accountId || ''
    const description = `Name: ${firstName} ${lastName}\\nEmail Address: ${yourEmailAddress}\\nPhone Number: ${contactNumber}\\nReason: ${reason}\\nSubreason: ${subreason === 'TV & Voice' ? 'TV and Voice' : subreason}\\nAddress: ${addressLine_1},${addressLine_2},${addressLine_3},${townOrCity}\\nPostcode: ${postcode}\\nDescription: ${enquiryDetails.replace(/(?:\r?\n|\r|['"]+)/g, ' ')}`

    const result: any = await client.contactUs.createWorkItem(
      reasonForContacting,
      subreason ? subreason : '',
      firstName,
      lastName,
      yourEmailAddress,
      addressLine_1 ? addressLine_1 : '',
      addressLine_2 ? addressLine_2 : '',
      addressLine_3 ? addressLine_3 : '',
      townOrCity ? townOrCity : '',
      postcode ? postcode : '',
      contactNumber,
      description,
      accountId,
      customerCheck?.customerId || '',
    )

    if (result && result.statusList?.[0]?.statusDescription === 'SUCCESS') {
      marketingEvents.simpleEvent('Contact_Us_Form_submission_success', options)
      location.assign('/thanks-for-your-request')
    } else {
      marketingEvents.simpleEvent('Contact_Us_Form_submission_failure', options)
      marketingEvents.error(options, 'ERR-CONTACT-US-1', 'Contact Us Form - submission failure', result.message)
      logger.error(result.message, result.error)
      setError(true)
    }
  })

  return (
    <FormContext {...methods}>
      <Container className={classes.wrapper}>
        <form onSubmit={onSubmit}>
          <Grid container item direction="column">
            <Box pt={6}>
              <Typography variant="h1" noWrap>
                {title}
              </Typography>
              <Typography variant="body2">{sub_title}</Typography>
            </Box>
            <Box pt={6}>
              <TextInput
                className={classes.fieldClass}
                gridWidth={12}
                required
                label="First Name"
                handleChange={specialCharactersSanitizer}
                validation={{
                  pattern: {
                    value: nameRegex,
                    message: 'Please use a valid name. Insert alphabetic characters only',
                  },
                  minLength: {
                    value: 1,
                    message: 'Minimum length is 3 characters. Please use a valid name.',
                  },
                  maxLength: {
                    value: 25,
                    message: 'Maximum length is 25 characters.  Please use a valid name.',
                  },
                  validate: (value) => value.trim().length > 0 || 'First Name is required and cannot be just spaces',
                }}
              />
            </Box>
            <Box pt={6}>
              <TextInput
                className={classes.fieldClass}
                gridWidth={12}
                required
                label="Last Name"
                handleChange={specialCharactersSanitizer}
                validation={{
                  pattern: {
                    value: nameRegex,
                    message: 'Please use a valid name. Insert alphabetic characters only',
                  },
                  minLength: {
                    value: 1,
                    message: 'Minimum length is 3 characters. Please use a valid name.',
                  },
                  maxLength: {
                    value: 25,
                    message: 'Maximum length is 25 characters. Please use a valid name.',
                  },
                  validate: (value) => value.trim().length > 0 || 'Last Name is required and cannot be just spaces',
                }}
              />
            </Box>
            <Box pt={6}>
              <TextInput
                className={classes.fieldClass}
                gridWidth={12}
                required
                label="Your email address"
                placeholder="me@example.co.uk"
                type="email"
                handleChange={triggerEmailVerification}
                validation={{ validate: validateExperianEmail }}
              />
            </Box>
            <Box pt={6}>
              <TextInput
                className={classes.fieldClass}
                gridWidth={12}
                required
                label="Contact Number"
                id="contactNumber"
                type="tel"
                inputProps={{
                  maxLength: 11,
                  autoComplete: 'off',
                }}
                onKeyDown={handleKeyDown}
                handleChange={handlePhoneChange}
                validation={{
                  maxLength: {
                    value: 11,
                    message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                  },
                  minLength: {
                    value: 11,
                    message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                  },
                  pattern: {
                    value: phoneRegex,
                    message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                  },
                  validate: validateExperianPhone,
                }}
              />
            </Box>
            <Box py={6}>
              <FormControlLabel
                className={classes.checkboxesContainer}
                label="I am a current Community Fibre customer"
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked)
                    }}
                  />
                }
              />
            </Box>
            {checked &&
            <>
              <legend>
                <Typography variant="body2">
                  Please enter your address below so we can help give you more specific advice
                </Typography>
              </legend>
              <Box pt={4}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Address line 1"
                  placeholder="Flat 24 Gisburn House"
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: addressRegex,
                      message: 'Please use a valid address. Insert alphabetic characters and numbers.',
                    },
                    minLength: {
                      value: 4,
                      message: 'Minimum length is 4 characters. Please use a valid address.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum length is 50 characters. Please use a valid address.',
                    },
                  }}
                />
              </Box>
              <Box pt={6}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Address line 2"
                  placeholder="Friary Estate"
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: addressRegex,
                      message: 'Please use a valid address. Insert alphabetic characters and numbers.',
                    },
                    minLength: {
                      value: 4,
                      message: 'Minimum length is 4 characters. Please use a valid address.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum length is 50 characters. Please use a valid address.',
                    },
                  }}
                />
              </Box>
              <Box pt={6}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Address line 3"
                  placeholder=""
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: addressRegex,
                      message: 'Please use a valid address. Insert alphabetic characters and numbers.',
                    },
                    minLength: {
                      value: 4,
                      message: 'Minimum length is 4 characters. Please use a valid address.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum length is 50 characters. Please use a valid address.',
                    },
                  }}
                />
              </Box>
              <Box pt={6}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Town or city"
                  placeholder="London"
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: /^(?!.*(?<char>[a-zA-Z\s-])\k<char>{2})(?!.*\s{2})[a-zA-Z\s-]+$/,
                      message: 'Please use a valid city name. Insert alphabetic characters only',
                    },
                    minLength: {
                      value: 3,
                      message: 'Minimum length is 3 characters. Please use a valid city name.',
                    },
                    maxLength: {
                      value: 25,
                      message: 'Maximum length is 25 characters. Please use a valid city name.',
                    },
                  }}
                />
              </Box>
              <Box pt={6}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Postcode"
                  placeholder="SE15 1SE"
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: /^[a-zA-Z]{1,2}\d[a-zA-Z\d]? \d[a-zA-Z]{2}$/,
                      message: 'Please use a valid UK postcode. Insert alphabetic characters and numbers (I.e SE15 1SE)',
                    },
                    minLength: {
                      value: 4,
                      message: 'Minimum length is 4 characters. Please use a valid UK postcode.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum length is 50 characters. Please use a valid UK postcode.',
                    },
                  }}
                />
              </Box>
            </>}
            <Box pt={checked && 6}>
              <FormControl required className={cx(classes.selectInput, reasonError && classes.reasonError)}>
                <InputLabel htmlFor="reasonForContacting">
                  Reason For Contacting
                </InputLabel>
                <Box pt={4}>
                  <Typography variant="body2">
                    Please select your contact reason carefully as this will help
                    your request to be solved faster.
                  </Typography>
                </Box>
                <Controller
                  id="reasonForContacting"
                  control={control}
                  rules={{ required: true }}
                  name="reasonForContacting"
                  defaultValue="Please select a reason"
                  renderValue={(selected: string) => {
                    if (!selected) {
                      return 'Please select a reason'
                    }

                    setReasonValue(selected)
                    return reasonValue
                  }}
                  as={
                    <Select>
                      {contactReasonsList.map((reason) => (
                        <MenuItem key={reason} value={reason}>
                          {reason}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {reasonError && <Typography variant="body2">Reason is required</Typography>}
              </FormControl>
              {showSubreasons &&
              <Box mt={6}>
                <FormControl required className={cx(classes.selectInput, subreasonError && classes.reasonError)}>
                  <InputLabel htmlFor="subreason">
                    More detailed reason
                  </InputLabel>
                  <Controller
                    id="subreason"
                    control={control}
                    rules={{ required: true }}
                    name="subreason"
                    defaultValue="Please select a more detailed reason"
                    renderValue={(selected: string) => {
                      if (!selected) {
                        return 'Please select a more detailed reason'
                      }

                      setSubreasonValue(selected)
                      return subreasonValue
                    }}
                    as={
                      <Select>
                        {subreasonsList.map((reason) => (
                          <MenuItem key={reason} value={reason}>
                            {reason}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                  {subreasonError && <Typography variant="body2">More detailed reason is required</Typography>}
                </FormControl>
              </Box>}
              {customBox &&
                <Box mt={3}>
                  <TextInput
                    className={classes.fieldClass}
                    gridWidth={12}
                    required
                    label="Please write your reason"
                    placeholder=""
                    type="text"
                  />
                </Box>}
            </Box>
            <Box mt={5}>
              <InputLabel htmlFor="enquiryDetails">
                Please fill in as much detail as possible about your enquiry*
              </InputLabel>
              <Box pt={2}>
                <Typography variant="body2">
                  The Contact Us form only allows letters, numbers and most punctuation.
                  If you have used any other characters, please remove them.
                  This form will not work if you are accessing it from overseas or via a VPN so we would recommend you use the chat function or just call us.
                </Typography>
              </Box>
              <Controller
                required
                control={control}
                name="enquiryDetails"
                id="enquiryDetails"
                defaultValue=""
                as={<textarea/>}
              />
            </Box>
            <Box pt={4}>
              <StyledButton type="submit" color="primary" disabled={isSubmitting}>
                <Typography noWrap>Submit</Typography>
                {isSubmitting && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
              </StyledButton>
            </Box>
            {error &&
            <Box pt={4}>
              <Paper id="message-error" className={classes.error}>
                <Box mb={2}>
                  <Typography variant="body2">
                    Oops something went wrong.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body2">
                    The Contact Us form only allows letters, numbers and most punctuation. If you have used the following characters or symbols ( ^/ _ / {'{'} {'}'} / [ ] / &lt; &gt; / &lsquo; / ~ / &rsquo; / ¬ / &acute; ) please remove them and resubmit.
                  </Typography>
                </Box>
                <Typography variant="body2">
                  Please note that this form will not work if you are accessing it from overseas or via a VPN so we would recommend you use the chat function or just call us.
                </Typography>
              </Paper>
            </Box>}
          </Grid>
        </form>
      </Container>
    </FormContext>
  )
}
