import { useRouter } from 'next/router'
import React, {
  useContext,
  useEffect, useState,
} from 'react'
import createCRMApiClient from '../../../../api/CRMApi'
import { Product } from '../../../../utils/commonInterfaces'
import { ButterCMSComponent } from '../../../../butter'
import { getOrderProducts } from '../../../../utils/getOrderProducts'
import marketingEvents from '../../../../utils/marketing/marketingEvents'
import {
  addToCartOptions, removeFromCart,
} from '../../../Packages/Helpers'
import { PackageProps } from '../../../Packages/Package'
import { ProductProps } from '../../../Products/Product'
import getStepUrl from '../../getStepUrl'
import {
  OrderOptions, useOrderContext,
} from '../../OrderContext'
import {
  ContainerWithPadding,
} from '../../../../styles/ContainerWithPadding'
import { OrderStep } from '../../../../pages/order/[...step]'
import Stepper from '../CheckoutStep/Stepper/Stepper'
import {
  Box, Collapse,
} from '@material-ui/core'
import { PremiseContext } from '../../../PremiseTracker/PremiseContext'
import { clearCart } from '../../../../pages/friends'
import WarningMessage, { WarningMessageColor } from '../../../WarningMessage/WarningMessage'
import { getPackageLength } from '../../../../utils/getProductDetails'

interface TabProps {
  getButterComponentByType: (type: string) => void;
  data: {
    components: ButterCMSComponent[];
  };
  step: OrderStep;
  options: OrderOptions;
  generalSettings: any;
  selectedBroadbandId?: string | null;
  setBundleOption: (bundle: PackageProps, channel: string, hide_strikethrough?: boolean) => void;
  setBroadband: (broadband: ProductProps, channel: string, hide_strikethrough: boolean) => void;
  selectedVoiceId?: string | null;
  selectedTVId?: string | null;
  setVoiceOption?: (voice: Product | PackageProps | ProductProps | null, channel: string) => void;
  setTVOption?: (tv: Product | PackageProps | ProductProps | null, channel: string) => void;
  selectedWIERId?: string | null;
  setWIEROption?: (wier: Product | PackageProps | ProductProps | null, channel: string) => void;
  selectedVASId?: string | null;
  setVASOption?: (vas: Product | PackageProps | ProductProps | null, channel: string) => void;
  isBundles: boolean;
  isEligibleFor3Giga?: boolean;
}

export const Tab = ({
  getButterComponentByType, data, selectedBroadbandId, setBundleOption, setBroadband, generalSettings, step,
}: TabProps) => {
  const {
    options, setOptions,
  } = useOrderContext()

  const router = useRouter()
  const { sprn } = router.query
  const sprnNumber = isNaN(Number(sprn)) ? undefined : Number(sprn)

  const client = createCRMApiClient()

  const {
    broadband, voice, tv, wier, vas, bundle, cartInstanceIdentifier,
  } = options

  const orderProducts = getOrderProducts({
    broadband,
    voice,
    tv,
    wier,
    vas,
    bundle,
  })

  const [
    isWarning,
    setIsWarning,
  ] = useState(false)

  const clickToCheckout = (product: ProductProps) => {
    if (broadband || bundle) {
      marketingEvents.addToCartFacebook(orderProducts)
    } else {
      marketingEvents.addToCartFacebook([product])
    }

    return router.push(getStepUrl('checkout', sprnNumber), undefined, { shallow: true })
  }

  const { premise } = useContext(PremiseContext)
  const getDataFromComponents = (component: any, type: string, dataType: 'id' | 'display_name', objectType: 'packages' | 'products') => {
    const object = component.find((e: PackageProps) => e.type === type)?.[objectType]
    return Object?.values(object)
      .map((component: PackageProps) => component && component?.[dataType])
  }

  const hidenType = 'hiden_for_box'
  const partnerID = premise?.coverage_indicator! === 'B' ? 'box' : 'cfl'
  const isBoxPartner = partnerID === 'box'
  const hidePackageId = getDataFromComponents(generalSettings.components, hidenType, 'id', 'packages')
  const specialContractLengthList = generalSettings.components.find((e: any) => e.type === 'general')?.special_contract_length
  const specialContractLengthArray = specialContractLengthList ? specialContractLengthList.split('|') : []
  const componentsData = data?.components as any
  const productContractLength = String(getPackageLength(broadband))
  const straightToExtras = generalSettings.components.find((e: any) => e.type === 'general')?.straight_to_extras

  function filterComponentsByName(
    componentsData: any,
    componentType: 'packages' | 'bundles' | 'products',
    targetId: string[],
  ): PackageProps | undefined {
    const currentComponent = componentsData.find((e: PackageProps) => e.type === componentType)

    if (!currentComponent) {
      return undefined
    }

    const filteredComponents: (any[] | undefined) = currentComponent[componentType]?.filter((item: any) => !targetId.includes(item.id))

    if (filteredComponents) {
      return {
        ...currentComponent,
        [componentType]: filteredComponents,
      }
    }

    return undefined
  }

  const filteredPackages = filterComponentsByName(componentsData, 'packages', hidePackageId as string[])
  useEffect(() => {
    if (isBoxPartner && broadband) {
      if (hidePackageId.includes(broadband.productOfferingId)) {
        setOptions((state) => ({
          ...state,
          ...clearCart,
        }))
        setIsWarning(true)
      }
    }
  }, [
    broadband,
    orderProducts,
  ])

  useEffect(() => {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [router])

  return (
    <div>
      {data.components.map(({
        type, ...rest
      }, i) => {
        const C: any = getButterComponentByType(type)
        const addPackage = async (product: ProductProps, channel: string, hide_strikethrough: boolean) => {
          if (!selectedBroadbandId) {
            await setBroadband(product, channel, hide_strikethrough)
            if (router.asPath.includes('bundles')) {
              if (straightToExtras) {
                router.push(getStepUrl('addons', sprnNumber))
              }
            } else {
              clickToCheckout(product)
            }

            return
          }

          const isPreselected = product.productOfferingId === selectedBroadbandId

          if (isPreselected) {
            clickToCheckout(product)
          }

          if (router.asPath.includes('bundles') && options.broadband) {
            await setBroadband(product, channel, hide_strikethrough)
            if (straightToExtras) {
              router.push(getStepUrl('addons', sprnNumber))
            }

            return
          }

          await setBroadband(product, channel, hide_strikethrough)
          if (straightToExtras) {
            router.push(getStepUrl('addons', sprnNumber))
          }
        }

        const addProduct = async (product: ProductProps, channel: string) => {
          const addons = [
            voice,
            tv,
            wier,
            vas,
          ]

          if (addons.some(e => e?.productOfferingId === product?.productOfferingId)) {
            const productOptions = await removeFromCart({
              product,
              client,
              cartInstanceIdentifier,
              channel,
              options,
            })

            return setOptions({
              ...options,
              ...productOptions,
            })
          }

          const productOptions = await addToCartOptions({
            product,
            options,
            client,
            channel,
          })

          return setOptions(productOptions)
        }

        if (!C) {
          return null
        }

        if (type === 'order_bottom_bar') {
          return (
            <C
              key={i}
              orderOptions={options}
              {...rest}
            />
          )
        }

        // Hide comparison table CTA on addons page
        if (type === 'copy_with_cta') {
          return (
            <Collapse
              in={step === 'broadband' || step === 'broadband-bundles'}
              timeout={0}
            >
              <C
                key={i}
                {...rest}
              />
            </Collapse>
          )
        }

        // Hide address banner on addons page
        if (type === 'banner_with_logo') {
          return (
            <Collapse
              in={step === 'broadband' || step === 'broadband-bundles'}
              timeout={0}
            >
              <C
                key={i}
                {...rest}
              />
            </Collapse>
          )
        }

        if (type === 'products') {
          return (
            <Collapse
              in={step === 'addons'}
              timeout={0}
            >
              <ContainerWithPadding>
                <Box mt={3}>
                  {!specialContractLengthArray.includes(productContractLength) &&
                  <Stepper currentStep={step} lessMargin/>}
                </Box>
              </ContainerWithPadding>

              <C
                key={i}
                {...rest}
                on_click_package={(product: ProductProps, channel: string) => addProduct(product, channel)}
              />
            </Collapse>
          )
        }

        if (type === 'bundles') {
          return (
            <Collapse
              in={step === 'broadband' || step === 'broadband-bundles'}
              timeout={0}
            >
              <ContainerWithPadding>
                <Box mt={3}>
                  {!specialContractLengthArray.includes(productContractLength) &&
                  <Stepper currentStep={step} lessMargin/>}
                </Box>
              </ContainerWithPadding>
              <C
                key={i}
                {...rest}
                on_click_package={(bundle: PackageProps, channel: string) => setBundleOption(bundle, channel)}
              />
            </Collapse>
          )
        }

        if (type === 'packages') {
          return (
            <Collapse
              in={step === 'broadband' || step === 'broadband-bundles'}
              timeout={0}
            >
              {isWarning &&
              <ContainerWithPadding>
                <Box pt={4}>
                  <WarningMessage
                    color={WarningMessageColor.blue}
                    text="Unfortunately, 3 Gbps Full Fibre Broadband is not available at this address <br> Why not try another speed?"
                  />
                </Box>
              </ContainerWithPadding>}
              <ContainerWithPadding>
                <Box mt={3}>
                  {!specialContractLengthArray.includes(productContractLength) &&
                  <Stepper currentStep={step} lessMargin/>}
                </Box>
              </ContainerWithPadding>
              <C
                key={i}
                {...isBoxPartner && filteredPackages ? filteredPackages : rest}
                on_click_package={(product: ProductProps, channel: string, hide_strikethrough: boolean) => addPackage(product, channel, hide_strikethrough)}
                on_click_bundle={(bundle: PackageProps, channel: string) => setBundleOption(bundle, channel)}
              />
            </Collapse>
          )
        }

        return (
          <C key={i} {...rest}/>
        )
      })}
    </div>
  )
}
