import React from 'react'
import {
  Typography, Box, useMediaQuery,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import HighlightingTag, { HighlightingTagProps } from './HighlightingTag'
import {
  PackageProps,
} from '../../Packages/Package'
import Features from '../../Packages/Features'
import Price from '../../Packages/Price'
import TypographyCMS from '../../Typography/Typography'
import theme from '../../../styles/theme'
import { getProductTerm } from '../../../utils/getProductDetails'

export const FeatureList = ({ features }: {features: String[]}) => {
  return (
    <ul>
      {features.map((feature, index) => (
        <li key={index}>
          <CheckIcon/>
          <Typography variant="body1">{feature}</Typography>
        </li>
      ),
      )}
    </ul>
  )
}

interface MediaPageBroadbandHeroProps {
  product: PackageProps;
  highlightingTag: HighlightingTagProps;
  hide_strikethrough?: boolean;
  price_guidance?: string;
  free_set_up_copy?: string;
  price?: string;
  old_price?: string;
}

const MediaPageBroadbandHero = ({
  product, highlightingTag, hide_strikethrough, price_guidance, free_set_up_copy, price, old_price,
}: MediaPageBroadbandHeroProps) => {
  const highlightingTagNotEmpty = Object.entries(highlightingTag!).length > 0
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const priceGuidance = price_guidance || 'month*'
  const outOfContractPrice = product?.out_of_contract_copy ? product.out_of_contract_copy : 'Just £2* more when out of contract'

  return (
    <Box>
      <Price
        product={product}
        variant="huge"
        subtitleVariant="overline"
        color="secondary"
        price_guidance={priceGuidance}
        term={getProductTerm(product)}
        showTerm
        hide_strikethrough={hide_strikethrough}
        price={price}
        old_price={old_price}
      />
      {highlightingTagNotEmpty && <HighlightingTag {...highlightingTag!}/>}
      <Box mt={4}>
        {product?.usage_description &&
          <TypographyCMS
            variant="body1"
            text={product.usage_description}
            weight={400}
            margin_bottom={2}
          />}
        <Features
          columns={isMobile ? 1 : 2}
          gap={1.25}
          bullet_size="large"
          bullet_color="secondary"
          bullet_gap={2}
          simple_bullets={product?.simple_bullets}
          bullet_out_of_contract_price={outOfContractPrice}
          bullets_crm={product}
          back_bullets={product?.bullets_back}
          bullets_with_icons={product?.bullets_with_icons}
          free_set_up_copy={free_set_up_copy}
        />
      </Box>
    </Box>
  )
}

export default MediaPageBroadbandHero
