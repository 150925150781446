import React from 'react'
import {
  Typography,
  Box,
  useMediaQuery,
} from '@material-ui/core'

import StyledButton from '../../StyledButton/StyledButton'
import classes from '../ResidentialPackageBanner.styles'
import theme from '../../../styles/theme'
import Features from '../../Packages/Features'
import Price from '../../Packages/Price'
import { getProductTerm } from '../../../utils/getProductDetails'
import { DiscountedProduct } from '../../../utils/commonInterfaces'
import { PackageProps } from '../../Packages/Package'
import { ChannelType } from '../../../utils/commonEnums'
import { addToCartOptions } from '../../Packages/Helpers'
import createCRMApiClient from '../../../api/CRMApi'
import { useOrderContext } from '../../NewOrderFlow/OrderContext'

export interface ResidentialPricingPackageProps {
  readonly product: PackageProps;
  readonly discount: Pick<DiscountedProduct, 'discountPrice' | 'standardPrice'> | null;
  readonly className?: string;
  readonly startSpeedAnimation: boolean;
}

export interface BannerFrontProps {
  product: PackageProps;
  discount?: ResidentialPricingPackageProps['discount'];
  buttonUrl?: string | undefined;
  buttonText?: string;
  onClickPackage?: Function;
  isModal?: boolean;
  title?: string;
  subtitle?: string;
  on_border_text?: string;
  no_spacing?: boolean;
  hide_strikethrough?: boolean;
  price_guidance?: string;
  free_set_up_copy?: string;
}

const BannerFront = ({
  product,
  buttonUrl,
  buttonText,
  onClickPackage,
  isModal,
  title,
  subtitle,
  on_border_text,
  no_spacing,
  hide_strikethrough,
  price_guidance,
  free_set_up_copy,
}: BannerFrontProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const priceGuidance = price_guidance || 'month*'

  const client = createCRMApiClient()
  const {
    options, setOptions,
  } = useOrderContext()

  function calcColumns() {
    if (isTablet) {
      return 2
    }

    if (isMobile) {
      return 1
    }

    return 3
  }

  const handleClick = async (e: React.ChangeEvent<{}>) => {
    e.stopPropagation()

    const channel = window.location.href.includes('utm_medium=affiliate') ? ChannelType.AFFILIATE : ChannelType.DIGITAL
    setOptions({
      ...options,
      channel,
    })

    const packageObj = {
      product: product!,
      options,
      client,
      channel,
      hide_strikethrough,
    }

    if (onClickPackage) {
      onClickPackage(product, channel, hide_strikethrough)
      return
    }

    const packageOptions = await addToCartOptions(packageObj)
    setOptions(packageOptions)
  }

  return (
    <Box
      className={classes.flipcard.cardSide}
      display={{
        sm: 'flex',
      }}
      position="relative"
      mt={no_spacing ? 0 : 7}
      overflow="inherit"
    >
      {on_border_text && <Typography variant="body2" className={classes.onBorderText}>{on_border_text}</Typography>}
      <Box
        px={3} py={3} flexDirection="column"
        textAlign="left" flexGrow={1} width={{
          md: 760,
        }}
      >
        {product?.display_name && <Typography variant="h3" color="primary">{`${title} ${product.display_name}`}</Typography>}
        <Box pt={isMobile ? 1 : 0.5} pb={isMobile ? 1 : 0}>
          <Typography variant="body1" color="primary">{subtitle}</Typography>
        </Box>
        {isMobile &&
        <Box display="flex" flexDirection="column">
          <Price
            product={product}
            showTerm
            term={getProductTerm(product)}
            price_guidance={priceGuidance}
            align="left"
            hide_strikethrough={hide_strikethrough}
            price={product.price}
            old_price={product.old_price}
          />
          <Typography variant="caption" align="left">{product.out_of_contract_copy ? product.out_of_contract_copy : 'Just £4* more when out of contract'}</Typography>
        </Box>}
        {product?.simple_bullets &&
          <Features
            columns={calcColumns()}
            columns_gap={3}
            mt={2}
            gap={isMobile ? 1.4 : 0.6}
            simple_bullets={product?.simple_bullets}
            bullets_crm={product}
            bullets_with_icons={product?.bullets_with_icons}
            bullet_size="large"
            free_set_up_copy={free_set_up_copy}
          />}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pb={3}
        px={3}
        className={classes.bottomContainer}
        width={{
          md: 260,
        }}
        borderRadius={{
          xs: 16,
          md: '0 10px 10px 0',
        }}
      >
        {!isMobile &&
        <Box display="flex" flexDirection="column" pb={2} px={{ lg: 3 }}>
          <Price
            product={product}
            showTerm
            term={getProductTerm(product)}
            price_guidance={priceGuidance}
            align="left"
            hide_strikethrough={hide_strikethrough}
            price={product.price}
            old_price={product.old_price}
          />
          <Typography variant="caption" align="left">{product.out_of_contract_copy ? product.out_of_contract_copy : 'Just £2* more when out of contract'}</Typography>
        </Box>}
        <StyledButton
          color="primary"
          isModal={isModal}
          url={buttonUrl}
          handleClick={handleClick}
        >
          {buttonText}
        </StyledButton>
      </Box>
    </Box>
  )
}

export default BannerFront
