/* eslint complexity: ["error", 100] */
import React, {
  useEffect, useRef, useState,
} from 'react'
import {
  Grid, Hidden, Box, CircularProgress,
} from '@material-ui/core'
import { cx } from 'linaria'
import classnames from 'classnames'

import classes from './MediaPageHeroRevamped.styles'
import createCRMApiClient from '../../../api/CRMApi'
import { useOrderContext } from '../../NewOrderFlow/OrderContext'
import MediaPageBroadbandHeroRevamped from './MediaPageBroadbandHeroRevamped'
import MediaPageBundleHero from './MediaPageBundleHero'
import theme from '../../../styles/theme'
import StyledButton from '../../StyledButton/StyledButton'
import { useRouter } from 'next/router'
import commonStyling from '../../../styles/commonStyling'
import useIntersectionObserver from '../../EnquiryBottomBar.js/EnquiryHook'
import { CtaProps } from '../../../utils/commonProps'
import MediaPageFlags, { VoucherProps } from './MediaPageVoucher'
import { useGeneralContext } from '../../GeneralContext/GeneralContext'
import { HighlightingTagProps } from './HighlightingTag'
import getStepUrl from '../../NewOrderFlow/getStepUrl'
import TrustPilotBox, { TrustPilotBoxProps } from '../../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import { PackageProps } from '../../Packages/Package'
import { ProductProps } from '../../Products/Product'
import { getPackages } from '../../../api/Packages'
import TypographyCMS from '../../Typography/Typography'
import { getProducts } from '../../../api/Products'
import { useAuth } from '../../../hooks/auth'
import { addToCartOptions } from '../../Packages/Helpers'
import {
  ChannelType, ContractType,
} from '../../../utils/commonEnums'
import { Product } from '../../../utils/commonInterfaces'
import OfferBanner, { OfferBannerProps } from '../../OfferBanner/OfferBanner'

export const defaultBoxProps = {
  color: theme.palette.primary.main,
  m: 1,
  border: 1,
  style: {
    width: '80px',
    height: '80px',
    display: 'grid',
    placeItems: 'center',
  },
}

export interface MediaPageHeroRevampedProps {
  image_src: string;
  mobile_image_src: string;
  image_alt: string;
  image_alignment: string;
  text_image_src?: string;
  text_image_alt?: string;
  button?: CtaProps;
  product_id: string;
  broadband_product_icon?: string;
  voice_product_id?: string;
  voice_product_icon?: string;
  tv_product_id?: string;
  tv_product_icon?: string;
  broadbandProduct?: PackageProps;
  voiceProduct?: Product;
  tvProduct?: Product;
  text_link_link?: string;
  text_link_text?: string;
  anchor_ref?: string;
  trustpilot_box: TrustPilotBoxProps;
  vouchers?: VoucherProps[];
  highlighting_tag?: HighlightingTagProps;
  show_web_chat?: boolean;
  offer_banner?: OfferBannerProps;
  redirect_to_postcode_search_page?: boolean;
  product_title_break_row?: string;
  package_broadband?: PackageProps[];
  product_voice?: ProductProps[];
  product_tv?: ProductProps[];
  channel?: string;
  hide_strikethrough?: boolean;
  free_set_up_copy?: string;
}

export function getPackageLabel(contract: Product['contractType']): string {
  switch (contract) {
    case ContractType.ANNUAL:
      return '12 month'

    case ContractType.MONTHLY:
      return '30 day'

    case ContractType.ANNUAL_24:
      return '24 month'
  }

  return ''
}

export default function MediaPageHeroRevamped({
  image_src, mobile_image_src, image_alignment, text_image_src, text_image_alt, button,
  text_link_link, text_link_text, broadband_product_icon, tv_product_icon, voice_product_icon, anchor_ref,
  vouchers, highlighting_tag, offer_banner, redirect_to_postcode_search_page, trustpilot_box,
  package_broadband, product_tv, product_voice, channel: butterChannel, hide_strikethrough, free_set_up_copy,
}: MediaPageHeroRevampedProps) {
  const router = useRouter()
  const { isAuthenticated } = useAuth()

  const {
    generalOptions, setGeneralOptions,
  } = useGeneralContext()

  const {
    sprn,
  } = generalOptions

  const [
    currentUrl,
    setCurrentUrl,
  ] = useState<string>()

  const [
    packageBroadband,
    setPackageBroadband,
  ] = useState<PackageProps>()

  const [
    productTv,
    setProductTv,
  ] = useState<ProductProps>()

  const [
    productVoice,
    setProductVoice,
  ] = useState<ProductProps>()

  const {
    options, setOptions,
  } = useOrderContext()

  const [
    loader,
    setLoader,
  ] = useState<any>(<CircularProgress size="7rem" color="inherit"/>)

  const client = createCRMApiClient()

  const mediaPageHeroRef = useRef<HTMLDivElement | null>(null)
  const [isMediaPageHeroVisible] = useIntersectionObserver({
    elementRef: mediaPageHeroRef,
  })

  setTimeout(() => {
    setLoader(<TypographyCMS variant="h1" align="center" text="We are sorry. This product is not available anymore. Please choose another one."/>)
  }, 3000)

  const setProducts = async () => {
    const affiliatePageCheck = currentUrl?.includes('utm_medium=affiliate') ? ChannelType.AFFILIATE : ChannelType.DIGITAL
    const settings = {
      options,
      client,
      channel: affiliatePageCheck,
      hide_strikethrough,
    }

    const optionsBroadband = packageBroadband && await addToCartOptions({
      ...settings,
      product: packageBroadband,
    })

    const optionsTv = productTv && await addToCartOptions({
      ...settings,
      product: productTv,
    })

    const optionsVoice = productVoice && await addToCartOptions({
      ...settings,
      product: productVoice,
    })

    setOptions({
      ...options,
      channel: affiliatePageCheck,
    })

    return setOptions({
      ...options,
      ...optionsBroadband,
      ...optionsTv,
      ...optionsVoice,
      voucher_flags: vouchers,
    })
  }

  const handlSecondaryButtonClick = () => {
    setProducts()
    router.push(`${router.asPath}${text_link_link}`, undefined, { shallow: true })
  }

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [])

  useEffect(() => {
    if (currentUrl) {
      setGeneralOptions({
        ...generalOptions,
        urlPath: currentUrl,
      })
    }
  }, [currentUrl])

  useEffect(() => {
    const awaitingSprn = setTimeout(() => {
      if (redirect_to_postcode_search_page && !sprn) {
        location.assign('/postcode-search')
      }
    }, 100)

    return () => {
      clearTimeout(awaitingSprn)
    }
  }, [
    sprn,
    redirect_to_postcode_search_page,
  ])

  useEffect(() => {
    setGeneralOptions({
      ...generalOptions,
      showStickyCheckAvailability: !isMediaPageHeroVisible,
    })
  }, [isMediaPageHeroVisible])

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const affiliatePageCheck = currentUrl?.includes('utm_medium=affiliate') ? ChannelType.AFFILIATE : ChannelType.DIGITAL
        const channelInCaps = butterChannel ? butterChannel.toUpperCase() : affiliatePageCheck
        const fetchedPackage = await getPackages(channelInCaps, package_broadband, 'RESIDENTIAL') as PackageProps[]
        const fetchedProductTV = await getProducts(product_tv!) as Array<ProductProps>
        const fetchedProductVoice = await getProducts(product_voice!) as Array<ProductProps>
        setPackageBroadband(fetchedPackage[0])
        setProductTv(fetchedProductTV[0])
        setProductVoice(fetchedProductVoice[0])
      })()
    }
  }, [
    isAuthenticated,
    package_broadband,
    product_tv,
    product_voice,
  ])

  const hasAddOn = Boolean(productTv || productVoice)
  const offerBanner = Object.entries(offer_banner!).length > 0

  return (
    <div className={classnames(classes.container, image_alignment === 'left' && classes.alignImageLeft)} id={anchor_ref} ref={mediaPageHeroRef}>
      <Grid className={classes.heroContainer} item>
        <Grid
          className={cx(classes.heroTextContainer, hasAddOn ? classes.heroBundlesContainer : commonStyling.maxWidthHalfGridLeft)}
          item
        >
          {packageBroadband ?
            <>
              {vouchers?.length ? <MediaPageFlags vouchers={vouchers}/> : <Box mb={3}/>}
              {packageBroadband?.display_name &&
              <TypographyCMS variant="h1" align={hasAddOn ? 'left' : 'center'} text={packageBroadband.display_name} margin_top={3} margin_bottom={2}/>}
              {hasAddOn ?
                <MediaPageBundleHero
                  broadbandProduct={packageBroadband!}
                  broadbandIcon={broadband_product_icon}
                  tvIcon={tv_product_icon}
                  tvProduct={productTv!}
                  voiceIcon={voice_product_icon}
                  voiceProduct={productVoice!}
                  free_set_up_copy={free_set_up_copy}
                /> :
                <MediaPageBroadbandHeroRevamped
                  product={packageBroadband!}
                  highlightingTag={highlighting_tag!}
                  hide_strikethrough={hide_strikethrough}
                  price_guidance={packageBroadband.price_guidance}
                  free_set_up_copy={free_set_up_copy}
                  price={packageBroadband.price}
                  old_price={packageBroadband.old_price}
                />}
              <Grid item>
                <Grid container alignItems="center" className={classes.bottomButtonContainer} spacing={2}>
                  {button && button.text && (
                    <Grid item>
                      {sprn ?
                        <StyledButton
                          color="primary"
                          url={getStepUrl('checkout-details', sprn)}
                          handleClick={setProducts}
                          className={cx(button.animated_icon && classes.animatedButtonIcon)}
                        >
                          {button.text}
                          {button.icon && <img src={button.icon} alt=""/>}
                        </StyledButton> :
                        <StyledButton
                          color="primary"
                          isModal={button.modal}
                          url={button.url}
                          handleClick={setProducts}
                          className={cx(button.animated_icon && classes.animatedButtonIcon)}
                        >
                          {button.text}
                          {button.icon && <img src={button.icon} alt=""/>}
                        </StyledButton>}
                    </Grid>
                  )}
                  {
                    text_link_link && text_link_text && (
                      <Grid item>
                        <StyledButton color="secondary" url={text_link_link} isFullWidth handleClick={() => handlSecondaryButtonClick()}>
                          {text_link_text}
                        </StyledButton>
                      </Grid>)
                  }
                </Grid>
                {offerBanner &&
                <Box mt={{
                  xs: 5,
                  md: 4,
                }}
                >
                  <OfferBanner {...offer_banner!}/>
                </Box>}
                {trustpilot_box && trustpilot_box.widget &&
                <Grid item xs>
                  <TrustPilotBox
                    background_color={trustpilot_box.background_color}
                    widget={trustpilot_box.widget}
                    padding={trustpilot_box.padding}
                    margin={trustpilot_box.margin}
                    wrapped={trustpilot_box.wrapped}
                    width={trustpilot_box.width}
                    inverted={trustpilot_box.inverted}
                    offset={trustpilot_box.offset}
                    mobile_center={trustpilot_box.mobile_center}
                  />
                </Grid>}
              </Grid>
            </> :
            loader}
        </Grid>
      </Grid>
      <div className={classes.imagesContainer}>
        <Hidden mdUp>
          <div
            id="mobile-media-page-hero-image"
            className={classes.mobileImageContainer}
          >
            <img
              src={mobile_image_src ? mobile_image_src : image_src}
              alt=""
            />
            <img
              className={classes.textImage}
              src={text_image_src}
              alt={text_image_alt}
            />
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={classes.image} style={{ backgroundImage: `url(${image_src})` }}/>
          <img
            className={classes.textImage}
            src={text_image_src}
            alt={text_image_alt}
          />
        </Hidden>
      </div>
    </div>
  )
}
