import { css } from 'linaria'
import theme from '../../styles/theme'

const classesOld = {
  slider: css`
    .slick-track {
      display: flex;
      justify-content: stretch;
      .slick-slide {
        float: none;
        height: auto;
        > div {
          height: 100%;
        }
      }
    }
    .slick-list {
      overflow: visible !important;
      transition: all ease-in-out .5s;
    }
    &:hover {
      .slick-arrow {
        visibility: visible;
      }
    }
    .slick-arrow {
      visibility: hidden;
      position: absolute;
      z-index: 10;
      background: #52465880;
      height: 160px;
      width: 42px;
      top: calc(50% - 15px);
      margin: 0 ${theme.spacing(3)}px;
      height: 160px;
      :before {
        color: transparent;
        border: solid white;
        opacity: 1;
        border-width: 0 3px 3px 0;
        display: inline-block;
      }
    }
    .slick-prev:before {
      position: relative;
      left: 2px;
      transform: rotate(135deg);
    }
    .slick-next:before {
      position: relative;
      right: 2px;
      transform: rotate(-45deg);
    }
  `,
  sliderOffCentre: css`
   .slick-list {
      ${theme.breakpoints.up('lg')} {
        left: -200px;
      }
    }
  `,
  bigScreenCards: css`
    display: grid;
    .slick-list {
      left: 0;
    }
    .slick-track {
      justify-content: center;
      width: 100vw !important;
    }
  `,
  modalScreen: css`
    pointer-events: none;
  `,
  modalBox: css`
    pointer-events: auto;
  `,
  flipcard: {
    selectedCard: css`
      border: 3px solid ${theme.palette.primary.main};
    `,
    cardFlipped: css`
      transform: rotateY(180deg) !important;
      transition: transform 1s;
      transform-style: preserve-3d;
    `,
    inner: css`
      display: flex;
      justify-content: center;
      min-width: 100%;
      position: relative;
      transform: rotateY(0);
      transition: transform 1s;
      transform-style: preserve-3d;
      height: 100%;

      ${theme.breakpoints.down('sm')} {
        margin-bottom: 15px;
      }
    `,
    front: css`
      transform: rotateY(0);
      overflow: visible;
    `,
    back: css`
      position: absolute;
      transform: rotateY(180deg);
      min-height: 100%;
      overflow: visible;
    `,
  },
  oldPrice: css`
    text-decoration: line-through;
    margin-right: ${theme.spacing(1)}px !important;
  `,
  price: {
    decimals: css`
      position: absolute;
      bottom: 100%;
      transform: translateY(${theme.spacing(0.5)}px);
    `,
    fraction: css`
      position: relative;
    `,
  },
  twistButton: css`
    margin-top: ${theme.spacing(1.5)}px;
    border: 0;
    background: none;
    cursor: pointer;
    &:focus:not(.focus-visible) {
      outline: none;
    }
  `,
  hide: css`
  display:none;
  `,
  selected: css`
    background-color: ${theme.palette.secondary.main} !important;
    color: white;
    opacity: unset !important;
  `,
  priceLabel: css`
    &.MuiTypography-root {
      margin-top: ${theme.spacing(0.25)}px;
    }
  `,
  huge: css`
    &[class*=MuiTypography] {
      font-size: 60px;
    }
    + .right [class*=fraction] {
      font-size: 20px;
    }
  `,
  fontWeightNormal: css`
    &.MuiTypography-body1 {
      font-weight: 400;
    }
  `,
  iconWithText: css`
    text-align: center;
    p:first-child {
      margin-top: 0;
    }
  `,
  spinner: css`
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.spacing(144)}px;
    height: ${theme.spacing(73.75)}px;
      div[class*='spinner'] {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
        right: unset;
        width: ${theme.spacing(15)}px;
        height: ${theme.spacing(15)}px;
        div {
          width: ${theme.spacing(15)}px;
          height: ${theme.spacing(15)}px;
        }
      }
      div[class*='messageWrapper'] {
        top: ${theme.spacing(15)}px;
      }
  `,
  error: css`
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.spacing(144)}px;
  `,
  root: css`
  position: relative;
  .MuiListItemAvatar-root {
    min-width: 0;
  }
  .MuiAvatar-colorDefault {
    background: none;
  }
  .MuiListItemText-root {
    margin: 0;
  }
  .MuiListItem-root {
    padding: 0;
  }
  .MuiAvatar-root {
    justify-content: flex-start;
    align-items: flex-start;
  }
  `,
}
export default classesOld
