import React, {
  useState, useEffect,
} from 'react'
import {
  Box,
  Typography, useMediaQuery,
} from '@material-ui/core'
import { useRouter } from 'next/router'
import SearchBar, {
  SearchBarProps,
} from '../SearchBar/SearchBar'
import IconsWithText, { IconsWithTextProps } from '../IconsWithText/IconsWithText'
import { BUNDLES_HASH } from '../NewOrderFlow/getStepUrl'
import Wysiwyg, { Align } from '../Wysiwyg/Wysiwyg'
import ModalBox from '../ModalBox/ModalBox'
import SimpleBox from '../SimpleBox/SimpleBox'
import theme, {
  COLORS, general,
} from '../../styles/theme'

export interface PostcodeSearchModalProps {
  readonly meta: {
    id: number;
  };
  title: string;
  body: string;
  search_bar: SearchBarProps;
  simple_link_on_desktop_only?: string;
  icons_with_text?: IconsWithTextProps;
}

export default function PostcodeSearchModal({
  meta,
  title,
  body,
  search_bar,
  simple_link_on_desktop_only,
  icons_with_text,
}: PostcodeSearchModalProps) {
  const [
    open,
    setOpen,
  ] = useState(false)
  const router = useRouter()

  const [
    goToBundles,
    setGoToBundles,
  ] = useState(false)

  const [
    goToCheckout,
    setGoToCheckout,
  ] = useState(false)

  const [
    goToHomeschool,
    setGoToHomeschool,
  ] = useState(false)

  useEffect(() => {
    if (router.asPath.includes('postcode-search-modal')) {
      setOpen(true)
    }

    if (router.asPath.includes(BUNDLES_HASH)) {
      setGoToBundles(true)
    }

    if (router.asPath.includes('#homeschool')) {
      setGoToHomeschool(true)
    }

    if (router.asPath.includes('#checkout')) {
      setGoToCheckout(true)
    }
  }, [router.asPath])

  const handleClose = () => {
    setOpen(false)
    router.back()
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!open) {
    return null
  }

  return (
    <ModalBox
      visible={open}
      sync={handleClose}
      aria-labelledby="postcode-search-modal"
      aria-describedby="postcode-search"
      id={`PostcodeSearchModal_${meta.id}`}
      bgcolor={COLORS.gray1}
      fullScreen={isMobile}
      maxWidth="1000px"
    >
      <Box mb={6}>
        <Typography variant="h3" component="h1" color="primary" align="center" gutterBottom>{title}</Typography>
        <Typography variant="body1" color="primary" align="center">{body}</Typography>
        <SearchBar goToBundles={goToBundles} goToCheckout={goToCheckout} goToHomeschool={goToHomeschool} {...search_bar}/>
        {simple_link_on_desktop_only &&
          <Wysiwyg
            body={simple_link_on_desktop_only}
            variant="body2"
            className={general.font.weight.book}
            contentAlignment={Align.center}
            removeMargins
          />}
      </Box>
      <Box pb={4}>
        <SimpleBox bgcolor={COLORS.white}>
          {icons_with_text && <IconsWithText {...icons_with_text!} items_per_row={3} noSection paddings={false}/>}
        </SimpleBox>
      </Box>
    </ModalBox>
  )
}
