import React from 'react'
import { useRouter } from 'next/router'
import { Box } from '@material-ui/core'
import { css } from 'linaria'
import Head from 'next/head'
import {
  KeyboardArrowLeft, KeyboardArrowRight,
} from '@material-ui/icons'
import theme, {
  COLORS, fontWeights,
} from '../../../styles/theme'
import fontSizes from '../../../styles/fontSizes'

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  selectedCategory: string;
}

const classes = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing(1)}px;
  `,
  pageLink: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.spacing(4)}px;
    height: ${theme.spacing(4)}px;
    font-weight: ${fontWeights.medium};
    text-decoration: none;
    color: ${COLORS.bodyTextPrimary};
    transition: all 150ms;
    margin: 0 3px;
    padding: ${theme.spacing(0, 0.75)};
    font-size: ${fontSizes.body2};
    box-sizing: border-box;
    font-style: normal;
    text-align: center;
    line-height: 1.4;
    border-radius: ${theme.spacing(2)}px;
    
    &:hover:not(.disabled) {
      background-color: ${COLORS.gray2};
    }
    
    &.active {
      background-color: ${COLORS.primary};
      color: ${COLORS.white};
      pointer-events: none;
    }
    
    &.disabled {
      color: ${COLORS.gray3};
      pointer-events: none;
    }

    .MuiSvgIcon-root {
      width: ${theme.spacing(2.5)}px;
      height: ${theme.spacing(2.5)}px;
    }
  `,
}

const CustomPagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  selectedCategory,
}) => {
  const router = useRouter()
  const currentPath = router.asPath
  const communityConnect = currentPath.includes('community-connect')
  // Determine base URL based on the current path
  const baseUrl = () => {
    if (communityConnect) {
      // Handle 'community-connect' specific paths
      return currentPath.includes('all-articles') ?
        '/community-connect/blog/all-articles' :
        '/community-connect/blog'
    }

    // Extract the first segment after the first '/' and before '?'
    const match = currentPath.match(/\/(?<segment>[^/?]+)/)
    return match?.groups?.segment ? `/${match.groups.segment}` : '/'
  }

  const href = selectedCategory === 'blog-post' ? '?' : `?category=${selectedCategory}&`

  const getPageUrl = (page: number) => {
    if (typeof window === 'undefined') {
      if (selectedCategory === 'blog-post') {
        return `${baseUrl}${page > 1 ? `?page=${page}` : ''}`
      }

      return `${baseUrl}${selectedCategory ? `?category=${selectedCategory}` : ''}${page > 1 ? `&page=${page}` : ''}`
    }

    const url = new URL(baseUrl(), window.location.origin)
    if (selectedCategory !== 'blog-post' && selectedCategory) {
      url.searchParams.set('category', selectedCategory)
    }

    if (page > 1) {
      url.searchParams.set('page', page.toString())
    }

    return url.toString()
  }

  const handlePageChange = (newPage: number) => {
    const query = { ...router.query }
    if (newPage === 1) {
      delete query.page
    } else {
      query.page = newPage.toString()
    }

    router.push({
      pathname: router.pathname,
      query,
    }, undefined, { shallow: true })
    scrollToFirstArticle()
  }

  const getPageNumbers = () => {
    const delta = 2
    const range = []
    const rangeWithDots = []

    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(totalPages - 1, currentPage + delta);
      i++
    ) {
      range.push(i)
    }

    if (currentPage - delta > 2) {
      rangeWithDots.push(1, '...')
    } else {
      rangeWithDots.push(1)
    }

    rangeWithDots.push(...range)

    if (currentPage + delta < totalPages - 1) {
      rangeWithDots.push('...', totalPages)
    } else if (totalPages > 1) {
      rangeWithDots.push(totalPages)
    }

    return rangeWithDots
  }

  const scrollToFirstArticle = () => {
    const element = document.querySelector('#blog-articles')
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return (
    <>
      <Head>
        {currentPage > 1 && (
          <link
            rel="prev"
            href={getPageUrl(currentPage - 1)}
          />
        )}
        {currentPage < totalPages && (
          <link
            rel="next"
            href={getPageUrl(currentPage + 1)}
          />
        )}
        {communityConnect && (
          <link
            rel="canonical"
            href={getPageUrl(currentPage)}
          />
        )}
      </Head>

      <Box className={classes.container}>
        <a
          href={`${href}page=${currentPage - 1}`}
          className={`${classes.pageLink} ${currentPage <= 1 ? 'disabled' : ''}`}
          onClick={(e) => {
            e.preventDefault()
            if (currentPage > 1) {
              handlePageChange(currentPage - 1)
            }
          }}
        >
          <KeyboardArrowLeft/>
        </a>

        {getPageNumbers()
          .map((pageNum, index) => (
            <React.Fragment key={index}>
              {pageNum === '...' ? (
                <span>...</span>
              ) : (
                <a
                  href={`${href}page=${pageNum}`}
                  className={`${classes.pageLink} ${currentPage === pageNum ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault()
                    handlePageChange(pageNum as number)
                  }}
                >
                  {pageNum}
                </a>
              )}
            </React.Fragment>
          ))}

        <a
          href={`${href}page=${currentPage + 1}`}
          className={`${classes.pageLink} ${currentPage >= totalPages ? 'disabled' : ''}`}
          onClick={(e) => {
            e.preventDefault()
            if (currentPage < totalPages) {
              handlePageChange(currentPage + 1)
            }
          }}
        >
          <KeyboardArrowRight/>
        </a>
      </Box>
    </>
  )
}

export default CustomPagination
